import { useEffect, useState } from "react";
import Table from "../../Partials/Table/table";
import { apiUrl } from "../../../config";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ConfirmationModal from "../../Partials/Modals/Confirmation/confirmation";
import Alert from "../../Partials/Modals/Alert/alert";

const Operators = () => {
    const [page,setPage] = useState(1);
    const [values,setValues] = useState([]);
    const [reason,setReason] = useState("");
    const [searchText,setSearchText] = useState("");
    const [confirmationModalForStatus,setConfirmationModalForStatus] = useState();
    const [confirmationModalForEdit,setConfirmationModalForEdit] = useState();
    const [confirmationModalForDelete,setConfirmationModalForDelete] = useState();
    const [alert,setAlert] = useState(false);
    const token = useSelector((state) => state.token);
    const userInfos = useSelector((state) => state.userInfos);
    const navigate = useNavigate();

    const [searchParam, setSearchParam] = useState("Email");
    const now = new Date();
    const today = new Date(now);
    today.setHours(0, 0, 0, 0);
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); 
    const day = String(today.getDate()).padStart(2, '0'); 
    const todayToIsoString = `${year}-${month}-${day}T00:00:00.000Z`;
    const [sort,setSort] = useState(1);
    const [filter,setFilter] = useState("");
    const [startDate, setStartDate] = useState(todayToIsoString);
    const [finishDate, setFinishDate] = useState(todayToIsoString);
    const [searchD, searchDate] = useState(false);
    const [dateParam, setDateParam] = useState("Kiralama Günü");

    const titles = ["id","Kullanıcı Adı","Email","Engel Durumu","Onay Durumu","Rolü","İşlemler"];
    const keys = ["id","username","email","blocked","confirmed","user_role.name","action"];

    useEffect(() => {
        async function fetchData() {
            let srt = sort == 1 ? 'sort=id:desc' : 'sort=id:asc';
            if (searchD) {
                srt += `&filters[$and][0][created_at][$lt]=${finishDate}&filters[$and][1][created_at][$gt]=${startDate}`
            }
            if (searchText !== "") {
                srt += '&filters[email][$containsi]=' + searchText
            }      
            let url = "/api/users?"+srt+"&filters[user_role][id][$null]&populate=user_role&pagination[start]="+ ((page - 1) * 10) +"&pagination[limit]=10";
            const response = await fetch(apiUrl + url,
            {
                headers: {
                    authorization: `Bearer ${token}`,
                }
            });
            const operators = await response.json()
            console.log(operators)
            setValues(operators);       
        }
        fetchData();
    },[page,sort,filter,startDate,finishDate,confirmationModalForDelete,searchText,confirmationModalForStatus]);

    const changeStatusForUser = async(row) => {
        setConfirmationModalForStatus(row);
    }

    const editOperator = (row) => {
        setConfirmationModalForEdit(row);
    }

    const deleteOperator = (row) => {
        setConfirmationModalForDelete(row);
    }

    let functions = [];
    if(userInfos && userInfos.admin_access_control && userInfos.admin_access_control.controls && userInfos.admin_access_control.controls["users-changeStatus"] && userInfos.admin_access_control.controls["users-changeStatus"].checked){
        functions.push({function: changeStatusForUser, tooltip: "Durumunu Değiştir", icon: "fa fa-check"});
    }
    if(userInfos && userInfos.admin_access_control && userInfos.admin_access_control.controls && userInfos.admin_access_control.controls["operators-edit"] && userInfos.admin_access_control.controls["operators-edit"].checked){
        functions.push({function: editOperator, tooltip: "Düzenle", icon: "fa fa-pencil-square-o"});
    }
    if(userInfos && userInfos.admin_access_control && userInfos.admin_access_control.controls && userInfos.admin_access_control.controls["operators-delete"] && userInfos.admin_access_control.controls["operators-delete"].checked){
        functions.push({function: deleteOperator, tooltip: "Sil",icon: "fa fa-trash"});
    }

    const redirectToAddOperatorPage = () => {
        navigate("/operator-ekle");
    }

    useEffect(() => {
        if(reason == "changeStatus"){
            async function fetchData() {
                const data = await fetch(apiUrl+'/api/users/' + confirmationModalForStatus.id + "?filters[user_role][name]=admin&pagination[page]=" + page + "&pagination[pageSize]=10", {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        confirmed: confirmationModalForStatus.confirmed ? false : true
                    })
                });
                let result = await data.json();
                if(result && result.id){
                    setConfirmationModalForStatus();
                    setAlert(true);
                }
            }
            fetchData();
        }else if(reason == "edit"){
            navigate('/operator-duzenleme/' + confirmationModalForEdit.id);
        }else if(reason == "delete"){
            async function fetchData() {
                const data = await fetch(apiUrl+'/api/users/' + confirmationModalForDelete.id, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        authorization: `Bearer ${token}`,
                    }
                });
                let result = await data.json();
                if(result && result.data && result.data.id){
                    setConfirmationModalForDelete();
                    setAlert(true);
                }
            }
            fetchData();
        }
    },[reason]);

    return(<>
        {alert ? <Alert title={"Operator Durumu"} description={reason == "delete" ? "Operator Silindi" : "Operator Düzenlendi."} state={alert} setState={setAlert}/> : null}
        {confirmationModalForStatus ? <ConfirmationModal title={"Durum Değişikliği"} description={"Durum değişikliği yapmayı onaylıyor musunuz?"} state={confirmationModalForStatus} setState={setConfirmationModalForStatus} setReason={setReason} reason={"changeStatus"}/> : null}
        {confirmationModalForEdit ? <ConfirmationModal title={"Operator Bilgisi Düzenleme"} description={"Düzenleme yapmak istediğinize emin misinz ?"} state={confirmationModalForEdit} setState={setConfirmationModalForEdit} setReason={setReason} reason={"edit"}/> : null}
        {confirmationModalForDelete ? <ConfirmationModal title={"Operator Bilgisi Silme"} description={"Silmek istediğinize emin misinz ?"} state={confirmationModalForDelete} setState={setConfirmationModalForDelete} setReason={setReason} reason={"delete"}/> : null}
        <div className="row">
            <div className="col-lg-12">
                <div className="content-wrapper">
                    <div className="row page">
                        <div className="col-lg-12 pageTitle">
                            <h1>Operatörler</h1>
                        </div>
                        <div className="col-lg-12 operatorAddButton">
                            <button className="btn btn-primary" onClick={() => redirectToAddOperatorPage()}>Operatör Ekle</button>
                        </div>
                        <div className="col-lg-12 pageContent">
                            <Table titles={titles}  values={values} valueTotalCount={values.totalCount} keys={keys} page={page} setPage={setPage} functions={functions} setSearchText={setSearchText}   setFilter={setFilter} setSort={setSort} setStartDate={setStartDate} setFinishDate={setFinishDate} searchDate={searchDate} dateParam={dateParam} searchParam={searchParam} />
                        </div>
                    </div>
            </div>
                </div>
        </div>
    </>);
}

export default Operators;