const Alert = ({title,description, state,setState}) => {
    return (
        <div className={state ? "modal fade show": "modal fade"} id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{display: state ? "block" : "none"}}>
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">{title}</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setState(false)}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    {description}
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => setState(false)}>Kapat</button>
                </div>
                </div>
            </div>
        </div>
    )
}

export default Alert;