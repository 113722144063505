import { useEffect, useState, useRef, useCallback } from "react";
import { useSelector } from "react-redux";
import {useNavigate } from 'react-router-dom';
import moment from "moment";
import { apiUrl } from "../../../config";
import ImageViewer from 'react-simple-image-viewer';


const ShowRental = () => {
    const [rentalInfo,setRentalInfo] = useState();
    const [getDeliveryPhotos,setGetDeliveryPhotos] = useState();
    const [getDeliveryStatus,setGetDeliveryStatus] = useState();
    const [makeDeliveryPhotos,setMakeDeliveryPhotos] = useState();
    const [makeDeliveryStatus,setMakeDeliveryStatus] = useState();
    const answer = useRef();
    const token = useSelector((state) => state.token);
    const navigate = useNavigate();
    const paths = window.location.pathname.split("/");
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const [currentImage2, setCurrentImage2] = useState(0);
    const [isViewerOpen2, setIsViewerOpen2] = useState(false);

    const openImageViewer = useCallback((index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);
    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };

    const openImageViewer2 = useCallback((index) => {
        setCurrentImage2(index);
        setIsViewerOpen2(true);
    }, []);
    const closeImageViewer2 = () => {
        setCurrentImage2(0);
        setIsViewerOpen2(false);
    };
    const messages={
        5:"İlk hali ile son hali arasında fark var!",
        6:"Fotoğraflar net değil.",
        7:"Aracımın fotoğrafı yüklü değil!",
        8:"Diğer"
    }

    useEffect(() => {
        if(paths && paths.length > 2){
            async function fetchData() {
                const data = await fetch(apiUrl + '/api/vehicle-requests/' + paths[2] + "?populate=assurance,get_delivery_images,make_delivery_images,vehicle,vehicle.user,vehicle.basket,users_permissions_requested_user", {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        authorization: `Bearer ${token}`,
                    }
                });
                const dataJson = await data.json()
                console.log({dataJson})
                setRentalInfo(dataJson);
                const deliveryPhotos = []
                for (let i = 0; i < dataJson.data.attributes.get_delivery_images.data.length; i++) {
                    const element = dataJson.data.attributes.get_delivery_images.data[i];
                    deliveryPhotos.push(apiUrl + element.attributes.url)
                }
                setGetDeliveryPhotos(deliveryPhotos);
                const deliveryPhotos2 = []
                for (let i = 0; i < dataJson.data.attributes.make_delivery_images.data.length; i++) {
                    const element = dataJson.data.attributes.make_delivery_images.data[i];
                    deliveryPhotos2.push(apiUrl + element.attributes.url)
                }
                setMakeDeliveryPhotos(deliveryPhotos2);
                setGetDeliveryStatus(dataJson.data.attributes.get_delivery_status);
                setMakeDeliveryStatus(dataJson.data.attributes.make_delivery_status);
            }
            fetchData();
        }
    },[])
    const cancel = () => {
        navigate('/kiraliklar');
    }


    return (<>
        <div className="row">
            <div className="col-lg-12">
                <div className="content-wrapper">
                    <div className="row page">
                        <div className="col-lg-12 pageTitle">
                            <h1>Kiralama Bilgisi Görüntüleme</h1>
                        </div>
                        <div className="col-lg-12 pageContent">
                            <div className="box box-info">
                                <form className="form-horizontal">
                                    <div className="box-body">
                                        <div className="form-group disabledClass">
                                            <label htmlFor="inputUserName" className="col-sm-2 control-label">Araç Sahibi</label>

                                            <div className="col-sm-10">
                                                <input type="text" className="form-control" id="inputUserName" placeholder="Araç Sahibi" defaultValue={rentalInfo && rentalInfo?.data?.attributes && rentalInfo?.data?.attributes?.vehicle?.data?.attributes?.user?.data ? rentalInfo?.data?.attributes?.vehicle?.data?.attributes?.user?.data?.attributes?.username : ""}/>
                                            </div>
                                        </div>
                                        <div className="form-group disabledClass">
                                            <label htmlFor="inputAnotherName" className="col-sm-2 control-label">Kiralayan</label>

                                            <div className="col-sm-10">
                                                <input type="text" className="form-control" id="inputAnotherName" placeholder="Kiralayan" defaultValue={rentalInfo && rentalInfo?.data?.attributes && rentalInfo?.data?.attributes?.users_permissions_requested_user?.data ? rentalInfo?.data?.attributes?.users_permissions_requested_user?.data?.attributes?.username : ""}/>
                                            </div>
                                        </div>
                                        <div className="form-group disabledClass">
                                            <label htmlFor="inputPlateNumber" className="col-sm-2 control-label">Plakası</label>

                                            <div className="col-sm-10">
                                                <input type="text" className="form-control" id="inputPlateNumber" placeholder="Plakası" defaultValue={rentalInfo && rentalInfo?.data?.attributes && rentalInfo?.data?.attributes?.vehicle.data ? rentalInfo?.data?.attributes?.vehicle.data?.attributes?.plate_number : ""}/>
                                            </div>
                                        </div>
                                        <div className="form-group disabledClass">
                                            <label htmlFor="inputDailyPrice" className="col-sm-2 control-label">Günlük Tutar</label>

                                            <div className="col-sm-10">
                                            <input type="text" className="form-control" id="inputDailyPrice" placeholder="Günlük Tutar" defaultValue={rentalInfo && rentalInfo?.data?.attributes ? rentalInfo?.data?.attributes?.daily_price  + "₺": ""}/>
                                            </div>
                                        </div>
                                        <div className="form-group disabledClass">
                                            <label htmlFor="inputHgs" className="col-sm-2 control-label">Hgs Seçimi</label>
                                            <div className="col-sm-10">
                                                <input type="text" className="form-control" id="inputHgs" placeholder="Hgs Seçimi" defaultValue={rentalInfo && rentalInfo?.data?.attributes ? rentalInfo?.data?.attributes?.hgs + "₺" : ""}/>
                                            </div>
                                        </div>
                                        <div className="form-group disabledClass">
                                            <label htmlFor="inputAssurance" className="col-sm-2 control-label">Sigorta Seçimi</label>

                                            <div className="col-sm-10">
                                                <input type="text" className="form-control" id="inputAssurance" placeholder="Sigorta Seçimi" defaultValue={rentalInfo && rentalInfo?.data?.attributes && rentalInfo?.data?.attributes?.assurance?.data ? rentalInfo?.data?.attributes?.assurance?.data?.attributes?.name : ""}/>
                                            </div>
                                        </div>
                                        <div className="form-group disabledClass">
                                            <label htmlFor="inputTotal" className="col-sm-2 control-label">Toplam Tutar</label>

                                            <div className="col-sm-10">
                                                <input type="text" className="form-control" id="inputTotal" placeholder="Toplam Tutar" defaultValue={rentalInfo && rentalInfo?.data?.attributes ? rentalInfo?.data?.attributes?.total  + "₺": ""}/>
                                            </div>
                                        </div>
                                        <div className="form-group disabledClass">
                                            <label htmlFor="inputStartDate" className="col-sm-2 control-label">Başlangıç Tarihi</label>

                                            <div className="col-sm-10">
                                                <input type="email" className="form-control" id="inputStartDate" placeholder="Başlangıç Tarihi" defaultValue={rentalInfo && rentalInfo?.data?.attributes && rentalInfo?.data?.attributes?.start_date ? moment(rentalInfo?.data?.attributes?.start_date).format("DD/MM/YYYY HH:mm") : ""}/>
                                            </div>
                                        </div>
                                        <div className="form-group disabledClass">
                                            <label htmlFor="inputEndDate" className="col-sm-2 control-label">Bitiş Tarihi</label>

                                            <div className="col-sm-10">
                                                <input type="email" className="form-control" id="inputEndDate" placeholder="Bitiş Tarihi" defaultValue={rentalInfo && rentalInfo?.data?.attributes ? moment(rentalInfo?.data?.attributes?.end_date).format("DD/MM/YYYY HH:mm") : ""}/>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="inputEndDate" className="col-sm-2 control-lcontrol-calabel">Teslim Alma Fotoğrafları</label>
                                            {getDeliveryStatus !== 0 && getDeliveryStatus !== 1 && getDeliveryStatus !== 2 && (
                                                <>
                                                    <span style={{fontWeight:"700"}} >Araç Sahibi Beyanı:</span> 
                                                    <label style={{ color: "red" }} className="col-sm-2 control-lcontrol-calabel">
                                                        {messages[getDeliveryStatus]}
                                                    </label>
                                                </>
                                            )}

                                            <label htmlFor="inputEndDate" className="col-sm-2 control-lcontrol-calabel"></label>
                                            <div className="col-sm-10">
                                            <div class="gallery-rent">        
                                            {getDeliveryPhotos && getDeliveryPhotos.length > 0 ? (
                                                getDeliveryPhotos.map((item,index) => (
                                                    <a>
                                                        <img
                                                            src={item}
                                                            onClick={ () => openImageViewer(index) }
                                                            width="300"
                                                            key={ index }
                                                            style={{ margin: '2px' }}
                                                            alt=""
                                                        />
                                                    </a>
                                                    ))
                                                    

                                                    ) : (
                                                <input type="text" className="form-control" placeholder="Bitiş Tarihi" defaultValue="Teslim Fotoğrafları Yüklenmedi."/>
                                                )}
                                                     {isViewerOpen && (
                                                        <ImageViewer
                                                        src={getDeliveryPhotos}
                                                        currentIndex={ currentImage }
                                                        disableScroll={ false }
                                                        closeOnClickOutside={ true }
                                                        onClose={ closeImageViewer }
                                                        />
                                                    )}
                                            </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="inputEndDate" className="col-sm-2 control-lcontrol-calabel">Teslim Etme Fotoğrafları</label>
                                            {makeDeliveryStatus !== 0 && makeDeliveryStatus !== 1 && makeDeliveryStatus !== 2 && (
                                                <>
                                                    <span style={{fontWeight:"700"}} >Araç Sahibi Beyanı:</span> 
                                                    <label style={{ color: "red" }} className="col-sm-2 control-lcontrol-calabel">
                                                        {messages[makeDeliveryStatus]}
                                                    </label>
                                                </>
                                            )}
                                            <div className="col-sm-10">
                                            <div class="gallery-rent">        
                                            {makeDeliveryPhotos && makeDeliveryPhotos.length > 0 ? (
                                                makeDeliveryPhotos.map((item,index) => (
                                                    <a>
                                                        <img
                                                            src={item}
                                                            onClick={ () => openImageViewer2(index) }
                                                            width="300"
                                                            key={ index }
                                                            style={{ margin: '2px' }}
                                                            alt=""
                                                        />
                                                    </a>
                                                    ))
                                                    

                                                    ) : (
                                                <input type="text" className="form-control" placeholder="Bitiş Tarihi" defaultValue="Teslim Fotoğrafları Yüklenmedi."/>
                                                )}
                                                     {isViewerOpen2 && (
                                                        <ImageViewer
                                                        src={makeDeliveryPhotos}
                                                        currentIndex={ currentImage2 }
                                                        disableScroll={ false }
                                                        closeOnClickOutside={ true }
                                                        onClose={ closeImageViewer2 }
                                                        />
                                                    )}
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="box-footer">
                                        <div className="row">
                                            <div className="col-lg-9"></div>
                                            <div className="col-lg-3"><button type="button" className="btn btn-default" onClick={() => cancel()}>Vazgeç</button></div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        



    </>);
}

export default ShowRental;