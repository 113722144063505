import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { apiUrl } from "../../../config";
import Alert from "../../Partials/Modals/Alert/alert";

const Settings = () => {
    const [alert,setAlert] = useState();
    const [errorAlert,setErrorAlert] = useState();
    const [id,setId] = useState();
    const [allValues,setAllValues] = useState();
    const [admins,setAdmins] = useState([]);
    const [selectedAdmin,setSelectedAdmin] = useState();
    const userInfos = useSelector((state) => state.userInfos);
    const token = useSelector((state) => state.token);
    const navigate = useNavigate();

    useEffect(() => {
        if(userInfos && userInfos.user_role && userInfos.user_role.name !== "superAdmin"){
            navigate("/dashboard");
        }else{
            async function fetchData() {
                const response = await fetch(apiUrl +"/api/users?filters[user_role][name]=admin",
                {
                    headers: {
                        authorization: `Bearer ${token}`,
                    }
                });
                const result = await response.json();
                setAdmins(result.data);       
            }
            fetchData();
        }
    },[]);

    useEffect(() => {
        if(selectedAdmin){
            async function fetchData() {
                const response = await fetch(apiUrl +"/api/admin-access-controls?populate=admin_user&filters[admin_user][id][$eq]=" + selectedAdmin,
                {
                    headers: {
                        authorization: `Bearer ${token}`,
                    }
                });
                let result = await response.json();
                if(result.data){
                    setId(result.data && result.data.length > 0 ? result.data[0].id : "");
                    setAllValues(result.data && result.data.length > 0 ? result.data[0].attributes.controls : "");       
                }
            }
            fetchData();
        }
    },[selectedAdmin]);

    const saveControls = async() => {
        let controls = getControls();
        const data = await fetch(apiUrl + '/api/admin-access-controls/' + id, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ 
                data: { 
                        controls: controls
                    }
                })
        });
        let result = await data.json();
        if(result.error && result.error.status == 400){
            setErrorAlert(true);
        }else{
            setAlert(true);
        }
    }

    const cancel = () => {
        navigate("/dashboard");
    }

    const getControls = () => {
        let inputs = document.querySelectorAll(".controls input");
        let newAllValues = JSON.parse(JSON.stringify(allValues));
        let controls = Object.values(Object.values(newAllValues));
        if(controls && controls.length > 0){
            let newObject = {};
            for(let i=0;i<controls.length;i++){
                if(inputs[i].checked){
                    controls[i].checked = true; 
                }else{
                    controls[i].checked = false;
                }
                newObject[Object.keys(newAllValues)[i]] = controls[i];
            }
            return newObject;
        }else{
            return {};
        }
    }

    return (<>
        {alert ? <Alert title={"Operator Tipi Ekleme"} description={"Operator Tipi Eklemesi Yapıldı."} state={alert} setState={setAlert}/> : null}
        {errorAlert ? <Alert title={"Operator Tipi Eklenemedi"} description={"Operator Tipi Eklemesi Yapılamadı."} state={errorAlert} setState={setErrorAlert}/> : null}
        <div className="row">
            <div className="col-lg-12">
                <div className="content-wrapper">
                    <div className="row page">
                        <div className="col-lg-12 pageTitle">
                            <h1>Admin Yetki Belirleme</h1>
                        </div>
                        <div className="col-lg-12 pageContent">
                            <div className="box box-info">
                                <form className="form-horizontal">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <select className="form-select" aria-label="Operator Tipi" onChange={(e) => setSelectedAdmin(e.target.value)}>
                                                <option defaultValue={"selected"}>Admin Seçiniz</option>
                                                {admins && admins.length > 0 ? 
                                                admins.map((item,index) => 
                                                    <option key={index} value={item.id}>{item.name + " " + item.surname}</option>
                                                ) : null}
                                            </select>
                                        </div>
                                        <div className="col-lg-5"></div>
                                        <div className="col-lg-4">
                                            <div className="row controls">
                                                {allValues && Object.values(allValues) && Object.values(allValues).length > 0 ? 
                                                    Object.values(allValues).map((item,index) =>
                                                        <div className="col-lg-12" key={index}>
                                                            <input className="form-check-input" type="checkbox" value="" id={"checked" + index} defaultChecked={item.checked}/>
                                                            <label className="form-check-label" id={"label" + index} htmlFor={"checked" + index }>
                                                                {item.title}
                                                            </label>
                                                        </div>) :null}
                                            </div>
                                        </div>
                                        <div className="col-lg-3"></div>
                                    </div>
                                    <div className="box-footer">
                                        <div className="row">
                                            <div className="col-lg-3"><button type="button" className="btn btn-default" onClick={() => cancel()}>Vazgeç</button></div>
                                            <div className="col-lg-6"></div>
                                            <div className="col-lg-3 text-right"><button type="button" className="btn btn-info" onClick={() => saveControls()}>Kaydet</button></div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>);
}
export default Settings;