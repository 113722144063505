const Form = ({keys,texts,refs,userInfo}) => {
    return(<>
        {refs && refs.length > 0 ? 
        refs.map((item,index) => 
            <div className="form-group" key={index}>
                <label htmlFor={texts[index]} className="col-sm-2 control-label">{texts[index]}</label>

                <div className="col-sm-10">
                    <input type={texts[index] == "Şifre" ? "password" : "text"} ref={item} className="form-control" id={texts[index]} defaultValue={userInfo ? userInfo[keys[index]] : ""} placeholder={texts[index]}/>
                </div>
            </div>
        ) : null}
    </>)
}

export default Form;