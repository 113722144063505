import moment from "moment";
import { useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import tr from 'date-fns/locale/tr';
import { registerLocale } from "react-datepicker";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import { addDays, setHours } from 'date-fns';
registerLocale('tr', tr);

const Table = ({ titles, values, valueTotalCount, keys, page, setPage, functions, setSearchText,
  setFilter, pageName, setSort, setStartDate, setFinishDate, searchDate, dateParam, searchParam, contents, setContent

}) => {
    const searchInput = useRef();
    const filterInput = useRef();
    const sort = useRef();
    
    const [startDate,startDateSet2] = useState(new Date());
    const [finishDate,finishDateSet2] = useState(new Date());

    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
      setIsModalOpen(true);
    };
  
    const closeModal = () => {
      setIsModalOpen(false);
    };
    const [state, setState2] = useState([
      {
        startDate: new Date(),
        endDate: addDays(new Date(), 7),
        key: 'selection'
      }
    ]);

    const keyPressFunc = (e) => {
        if(e.charCode === 13){
            setSearchText(searchInput.current.value);
        }
    }

    const search = () => {
        if(searchInput && searchInput.current){
            setSearchText(searchInput.current.value);
        }
    }

    const filterDate = () => {
          searchDate(true);
    }

  const [selectedValue, setSelectedValue] = useState('');

  const handleContentChange = (e) => {
    const selectedOption = e.target.value;
    setContent(contents[selectedOption]);
  }

    const handleSelectChange = (e) => {
      const selectedOption = e.target.value;
      setFilter(selectedOption);
      setSelectedValue(selectedOption); 
    };

    const handleSortChange = (e) => {
      const selectedOption = e.target.value;
      setSort(selectedOption);
    };

    const startDateHandler = (date) => {
      date.setHours(0, 0, 0, 0);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); 
      const day = String(date.getDate()).padStart(2, '0'); 
      const todayToIsoString = `${year}-${month}-${day}T00:00:00.000Z`;
      setStartDate(todayToIsoString);
      
    };

    const finishDateHandler = (date) => {
      date.setHours(0, 0, 0, 0);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); 
      const day = String(date.getDate()).padStart(2, '0'); 
      const todayToIsoString = `${year}-${month}-${day}T00:00:00.000Z`;
      setFinishDate(todayToIsoString);
    };


    function handleRangeSelect(ranges){
      setState2(ranges)
      const startDate = ranges[0].startDate
      const finishDate = ranges[0].endDate
      startDateHandler(startDate)
      finishDateHandler(finishDate)
      searchDate(true);
    }

    const modalOverlayStyle = {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    };


    const modalStyle = {
      background: 'white',
      padding: '20px',
      borderRadius: '8px',
      maxWidth: '975px',
      width: '100%',
    };
    

  return (
    <div className="row">
      <div className="col-xs-12">
        <div className="input-group searchBox">
          {pageName == "deliveries" && (
            <div style={{ marginRight: "100px" }} className="form-outline">
              <label htmlFor="inputName" className="col-sm-10 control-label">İhbar Durumu</label>
              <select className="form-control" value={selectedValue} onChange={handleSelectChange} ref={filterInput}>
                <option value="1">Hepsi</option>
                <option value="2">Sorun Bulunmayanlar</option>
                <option value="3">Teslim Almada Hata Bulunanlar</option>
                <option value="4">Teslim Etmede Hata Bulunanlar</option>
              </select>
            </div>
          )}
          { pageName == 'vehicle-accepts' ? <div style={{ marginRight: "100px" }} className="form-outline">
            <label htmlFor="inputName" className="col-sm-10 control-label">İçerik</label>
            <select className="form-control" onChange={handleContentChange} ref={sort}>
              <option value="0">Tüm araçlar</option>
              <option value="1">Onaylananlar</option>
              <option value="2">Onay bekleyenler</option>
            </select>
          </div> : null }
          <div style={{ marginRight: "100px" }} className="form-outline">
            <button className="form-control" onClick={openModal}>
              <i className="fa fa-calendar"></i>
              Tarih Seçimi
            </button>
          </div>
          {isModalOpen && (
            <div style={modalOverlayStyle}>
              <div style={modalStyle}>
                <DateRangePicker
                  onChange={item => handleRangeSelect([item.selection])}
                  locale={tr}
                  showSelectionPreview={true}
                  moveRangeOnFirstSelection={false}
                  months={2}
                  ranges={state}
                  inputRanges={[]}
                  staticRanges={[
                    {
                      label: 'Bugün',
                      hasCustomRendering: false,
                      range: () => ({
                        startDate: new Date(),
                        endDate: new Date()
                      }),
                      isSelected() {
                        return true;
                      }
                    },
                    {
                      label: 'Dün',
                      hasCustomRendering: false,
                      range: () => ({
                        startDate: new Date((new Date()).setDate(new Date().getDate() - 1)),
                        endDate: new Date((new Date()).setDate(new Date().getDate() - 1))
                      }),
                      isSelected() {
                        return true;
                      }
                    },
                    {
                      label: 'Son 7 Gün',
                      hasCustomRendering: false,
                      range: () => ({
                        startDate: new Date((new Date()).setDate(new Date().getDate() - 7)),
                        endDate: new Date()
                      }),
                      isSelected() {
                        return true;
                      }
                    },
                    {
                      label: 'Son 30 Gün',
                      hasCustomRendering: false,
                      range: () => ({
                        startDate: new Date((new Date()).setDate(new Date().getDate() - 30)),
                        endDate: new Date()
                      }),
                      isSelected() {
                        return true;
                      }
                    },
                    {
                      label: 'Bu Ay',
                      hasCustomRendering: false,
                      range: () => {
                        const today = new Date();
                        const startDate = new Date(today.getFullYear(), today.getMonth(), 1);
                        const endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
                        return { startDate, endDate };
                      },
                      isSelected() {
                        return true;
                      }
                    },
                    {
                      label: 'Geçen Ay',
                      hasCustomRendering: false,
                      range: () => {
                        const today = new Date();
                        const firstDayOfCurrentMonth = new Date(today.getFullYear(), today.getMonth(), 1);
                        const firstDayOfPreviousMonth = new Date(firstDayOfCurrentMonth);
                        firstDayOfPreviousMonth.setMonth(firstDayOfPreviousMonth.getMonth() - 1);

                        const lastDayOfPreviousMonth = new Date(firstDayOfCurrentMonth);
                        lastDayOfPreviousMonth.setDate(0);

                        return {
                          startDate: firstDayOfPreviousMonth,
                          endDate: lastDayOfPreviousMonth
                        };
                      },
                      isSelected() {
                        return true;
                      }
                    },

                  ]} direction="horizontal"
                />
                <button style={{ backgroundColor: 'rgb(237 93 106)', borderColor: 'rgb(237 93 106)' }} className="btn btn-danger" onClick={closeModal}>Kapat</button>
              </div>
            </div>
          )}

          <div style={{ marginRight: "100px" }} className="form-outline">
            <label htmlFor="inputName" className="col-sm-10 control-label">Sıralama</label>
            <select className="form-control" onChange={handleSortChange} ref={sort}>
              <option value="1">ID'ye göre azalan</option>
              <option value="2">ID'ye göre artan</option>
            </select>
          </div>

          <div className="form-outline">
            <label htmlFor="inputName" className="col-sm-10 control-label">{`${searchParam}`} </label>
            <input type="search" id="form1" className="form-control" placeholder="Arama" onKeyPress={(e) => keyPressFunc(e)} ref={searchInput} />
          </div>
          <button type="button" className="btn btn-primary" onClick={() => search()}>
            <i className="fa fa-search"></i>
          </button>
        </div>

      </div>
      <div className="col-xs-12">
        <div className="box">
          <div className="box-body">
            <table id="example1" className="table table-bordered table-striped">
              <thead>
                <tr>
                  {titles && titles.length > 0 ?
                    titles.map((item, index) =>
                      <th key={index}>{item}</th>
                    ) : null
                  }
                </tr>
              </thead>
              <tbody>
                {values && values.length > 0 ? (
                  values.map((item, index) => (
                    <tr key={index}>
                      {
                        keys.map((el, i) => {
                          const elSplitArr = el?.split(".");
                          return (<td key={i}>
                            {el !== "action" ? (
                              elSplitArr.length > 7 && item[elSplitArr[0]] ? (
                                titles[i] === "Tarih" ? (
                                  <span>
                                    {item[elSplitArr[0]][elSplitArr[1]]
                                      ? moment(item[elSplitArr[0]][elSplitArr[1]]).format(
                                        "DD/MM/YYYY HH:mm"
                                      )
                                      : ""}
                                  </span>
                                ) :
                                  <span>
                                    {item[elSplitArr[0]]?.[elSplitArr[1]]?.[elSplitArr[2]]?.[elSplitArr[3]]?.[elSplitArr[4]]?.[elSplitArr[5]]?.[elSplitArr[6]]?.[elSplitArr[7]]}
                                  </span>

                              ) : elSplitArr.length > 4 && item[elSplitArr[0]] ? (
                                titles[i] === "Tarih" ||
                                  titles[i] === "Ödeme Tarihi" ||
                                  titles[i] === "Onaylanma Tarihi" ? (
                                  <span>
                                    {item[elSplitArr[0]][elSplitArr[1]][elSplitArr[2]][
                                      elSplitArr[3]
                                    ][elSplitArr[4]]
                                      ? moment(
                                        item[elSplitArr[0]][elSplitArr[1]][
                                        elSplitArr[2]
                                        ][elSplitArr[3]][elSplitArr[4]]
                                      ).format("DD/MM/YYYY HH:mm")
                                      : ""}
                                  </span>
                                ) : (<span>
                                  {item[elSplitArr[0]][elSplitArr[1]] &&
                                    item[elSplitArr[0]][elSplitArr[1]][elSplitArr[2]]
                                    ? item[elSplitArr[0]][elSplitArr[1]][
                                    elSplitArr[2]
                                    ][elSplitArr[3]][elSplitArr[4]]
                                    : ""}
                                </span>
                                )
                              ) : elSplitArr.length > 1 && item[elSplitArr[0]] ? (
                                titles[i] === "Tarih" ||
                                  titles[i] === "Ödeme Tarihi" ||
                                  titles[i] === "Onaylanma Tarihi" ||
                                  titles[i] === "Başlangıç Tarihi" ||
                                  titles[i] === "Eklenme Tarihi" ||
                                  titles[i] === "Kayıt Tarihi" ||
                                  titles[i] === "İlk Aktivite" ||
                                  titles[i] === "Son Aktivite" ||
                                  titles[i] === "Bitiş Tarihi" ||
                                  titles[i] === "Teslimat Tarihi" ? (
                                  <span>
                                    {item[elSplitArr[0]]
                                      ? moment(item[elSplitArr[0]][elSplitArr[1]]).format(
                                        "DD/MM/YYYY HH:mm"
                                      )
                                      : ""}
                                  </span>
                                ) : (
                                  <span>
                                    {item[elSplitArr[0]]
                                      ? item[elSplitArr[0]][elSplitArr[1]]
                                      : ""}
                                  </span>
                                )
                              ) : typeof item[el] === "boolean" ? (
                                item[el] ? (
                                  <span>Evet</span>
                                ) : (
                                  <span>Hayır</span>
                                )
                              ) : (
                                <span>{item[el]}</span>
                              )
                            ) : (
                              <span>
                                {functions && functions.length > 0
                                  ? functions.map((elem, index) => (
                                    <button
                                      key={index}
                                      className="btn btn-primary"
                                      onClick={() => elem.function(item)}
                                      title={elem.tooltip}
                                      style={{ marginLeft: 10, marginRight: 10 }}
                                    >
                                      <i className={elem.icon}></i>
                                    </button>
                                  ))
                                  : null}
                              </span>
                            )}
                          </td>)
                        })
                      }
                    </tr>
                  ))
                ) : null}

              </tbody>
            </table>
            <div className="row">
              <div className="paginationContainer">
                {Math.ceil(valueTotalCount / 10) !== 1 ?
                  <div className="pagination">
                    {valueTotalCount && valueTotalCount > 0 ? <>
                      {page !== 1 ?
                        <a href="#" onClick={() => setPage((old) => old - 1)}>&laquo;</a> : null}
                      {Array(Math.ceil(valueTotalCount / 10)).fill(0).map((item, index) =>
                        page == index + 1 ? <a href="#" key={index} className="active" onClick={() => setPage(index + 1)}>{index + 1}</a> : <a href="#" key={index} onClick={() => setPage(index + 1)}>{index + 1}</a>
                      )}
                      {page !== Math.ceil(valueTotalCount / 10) ?
                        <a href="#" onClick={() => setPage((old) => old + 1)}>&raquo;</a> : null} </>
                      : null}
                  </div> : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Table;