import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { apiUrl } from "../../../config";
import Alert from "../../Partials/Modals/Alert/alert";

const OperatorTypeEdit = () => {
    const [alert,setAlert] = useState();
    const [errorAlert,setErrorAlert] = useState();
    const [values,setValues] = useState();
    const [allValues,setAllValues] = useState();
    const operatorTypeName = useRef();
    const token = useSelector((state) => state.token);
    const navigate = useNavigate();
    const paths = window.location.pathname.split("/");

    useEffect(() => {
        if(paths && paths.length > 2){
            async function fetchData() {
                const response = await fetch(apiUrl +"/api/user-roles/"+ paths[2],
                {
                    headers: {
                        authorization: `Bearer ${token}`,
                    }
                });
                let result = await response.json();
                if(result.data){
                    operatorTypeName.current.value = result.data.attributes.name;
                    setValues(Object.values(result.data.attributes.pages));       
                }
            }
            fetchData();
        }
    },[]);

    const editOperatorType = async() => {
        let pages = getPages(document.querySelectorAll(".operatorTypes input"));
        const data = await fetch(apiUrl + '/api/user-roles/' + paths[2], {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ 
                data: { 
                    "name": operatorTypeName.current ? operatorTypeName.current.value : "",
                    "pages": pages
                    }
                })
        });
        let result = await data.json();
        if(result.error && result.error.status == 400){
            setErrorAlert(true);
        }else{
            setAlert(true);
        }
    }

    const cancel = () => {
        navigate("/operator-tipleri");
    }

    const getPages = (inputs) => {
        let pages = JSON.parse(JSON.stringify(values));
        if(pages && pages.length > 0){
            for(let i=0;i<pages.length;i++){
                if(inputs[i].checked){
                    pages[i].checked = true;
                }else{
                    pages[i].checked = false;
                }
            }
        }
        return pages;
    }

    return (<>
        {alert ? <Alert title={"Operator Tipi Güncelleme"} description={"Operator Tipi Güncellemesi Yapıldı."} state={alert} setState={setAlert}/> : null}
        {errorAlert ? <Alert title={"Operator Tipi Güncellenemedi"} description={"Operator Tipi Güncellemesi Yapılamadı."} state={errorAlert} setState={setErrorAlert}/> : null}
        <div className="row">
            <div className="col-lg-12">
                <div className="content-wrapper">
                    <div className="row page">
                        <div className="col-lg-12 pageTitle">
                            <h1>Operator Tipi Güncelleme</h1>
                        </div>
                        <div className="col-lg-12 pageContent">
                            <div className="box box-info">
                                <form className="form-horizontal">
                                    <div className="box-body">
                                        <div className="form-group">
                                            <label htmlFor="inputName" className="col-sm-2 control-label">Operator Tipi Adı</label>
                                            <div className="col-sm-10">
                                                <input type="text" ref={operatorTypeName} className="form-control" id="inputUserName" placeholder="Operator Tipi Adı"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row operatorTypes">
                                        <div className="col-lg-3">
                                            <div className="row">
                                                {values && values.length > 0 ? 
                                                values.map((item,index) => 
                                                <div className="col-lg-12" key={index}>
                                                    <input className="form-check-input" type="checkbox" value="" id={"checked" + index} defaultChecked={item.checked}/>
                                                    <label className="form-check-label" htmlFor={"checked" + index}>
                                                        {item.title}
                                                    </label>
                                                </div>) : null}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="box-footer">
                                        <div className="row">
                                            <div className="col-lg-3"><button type="button" className="btn btn-default" onClick={() => cancel()}>Vazgeç</button></div>
                                            <div className="col-lg-4"></div>
                                            <div className="col-lg-3 text-right"><button type="button" className="btn btn-info" onClick={() => editOperatorType()}>Güncelle</button></div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>);
}
export default OperatorTypeEdit;