import {useEffect, useState} from "react";
import Table from "../../Partials/Table/table";
import {apiUrl} from "../../../config";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import ConfirmationModal from "../../Partials/Modals/Confirmation/confirmation";
import Alert from "../../Partials/Modals/Alert/alert";
import {id} from "date-fns/locale";

const CouponList = () => {
    const token = useSelector((state) => state.token);
    const userInfos = useSelector((state) => state.userInfos);
    const navigate = useNavigate();
    const [values, setValues] = useState([]);
    const [valuesCount, setValuesCount] = useState(0);
    const [confirmationModalForEdit, setConfirmationModalForEdit] = useState();
    const [confirmationModalForDelete, setConfirmationModalForDelete] = useState();
    const [reason, setReason] = useState("");
    const [alert, setAlert] = useState(false);


    //#region FILTER STATES
    const [page, setPage] = useState(1);
    const [sort, setSort] = useState(1);
    const [searchD, searchDate] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [finishDate, setFinishDate] = useState("");
    const [searchCoupon, setSearchCoupon] = useState("");
    //#endregion

    //#region COLUMN CONFIG
    const titles = ["Id", "Kod",
        "Oran", "Fiyat",
        "Kullanım Limiti", "Kullanım Sayısı",
        "Son Kullanım", "Oluşturulma Tarihi",
        "Güncellenme Tarihi", "İşlemler"];
    const keys = ["id", "attributes.code",
        "attributes.discount_rate", "attributes.discount_amount",
        "attributes.usage_limit", "attributes.usage_count",
        "attributes.expiration_date", "attributes.createdAt",
        "attributes.updatedAt", "action"];
    //#endregion

    useEffect(() => {
        // this code below just remove some warning message
        (async () => {
            await fetchData();
        })();
    }, [sort, startDate, finishDate, page, searchCoupon]);

    useEffect(() => {
        switch (reason) {
            case 'edit':
                editCouponRedirect();
                break;
            case 'delete':

                (async () => {
                    const couponId = confirmationModalForDelete.id;
                    const url = `/api/coupon-codes/${couponId}`
                    const data = await fetch(apiUrl + url, {
                        method: 'DELETE', headers: {
                            'Content-Type': 'application/json', authorization: `Bearer ${token}`,
                        }
                    });
                    const result = await data.json();
                    if (result && result.data && result.data.id) {
                        setConfirmationModalForDelete(null);
                        setAlert(true);
                        await fetchData();
                    }
                })();
                break;
        }


    }, [reason]);

    const fetchData = async () => {
        let str = sort === 1 ? 'sort=id:desc' : 'sort=id:asc';
        if (searchD) {
            const formattedFinishDate = new Date(finishDate);
            formattedFinishDate.setHours(23, 59, 59, 999);
            str += `&filters[$and][0][createdAt][$lt]=${formattedFinishDate.toISOString()}&filters[$and][1][createdAt][$gt]=${startDate}`;
        }
        if (searchCoupon !== "") {
            str += '&filters[code][$containsi]=' + searchCoupon
        }

        const url = `/api/coupon-codes?${str}&pagination[start]=${((page - 1) * 10)}&pagination[limit]=10`
        const response = await fetch(apiUrl + url, {
            headers: {
                authorization: `Bearer ${token}`,
            }
        });
        const result = await response.json()
        result.data.forEach(item => {

            if (item.attributes.expiration_date) {
                const epoch = item.attributes.expiration_date;
                const date = new Date(epoch * 1000);
                item.attributes.expiration_date = formatDateColumn(date);
            }

            const createdAt = new Date(item.attributes.createdAt);
            item.attributes.createdAt = formatDateColumn(createdAt);

            const updatedAt = new Date(item.attributes.updatedAt);
            item.attributes.updatedAt = formatDateColumn(updatedAt);
        })
        setValuesCount(result.meta.pagination.total);
        setValues(result.data);
    }

    //#region ACTION FUNC
    const formatDateColumn = (date) => {
        if (!date) return "";

        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();

        return `${day}/${month}/${year}`;
    }
    const redirectToAddOperatorPage = () => {
        navigate("/kupon-olustur");
    }
    const editCouponOpenModal = (row) => {
        setConfirmationModalForEdit(row);
    }
    const deleteCouponOpenModal = (row) => {
        setConfirmationModalForDelete(row);
    }
    const editCouponRedirect = () => {
        navigate(`/kupon-duzenleme/${confirmationModalForEdit.id}`);
    }
    //#endregion

    //#region TABLE ACTION BUTTONS
    let functions = [];
    if (userInfos && userInfos.admin_access_control && userInfos.admin_access_control.controls && userInfos.admin_access_control.controls["coupons-edit"] && userInfos.admin_access_control.controls["coupons-edit"].checked) {
        functions.push({function: editCouponOpenModal, tooltip: "Düzenle", icon: "fa fa-pencil-square-o"});
    }
    if (userInfos && userInfos.admin_access_control && userInfos.admin_access_control.controls && userInfos.admin_access_control.controls["coupons-delete"] && userInfos.admin_access_control.controls["coupons-delete"].checked) {
        functions.push({function: deleteCouponOpenModal, tooltip: "Sil", icon: "fa fa-trash"});
    }
    //#endregion

    return (<>
        {alert ? <Alert title={"Kupon Durumu"} description={reason == "delete" ? "Kupon Silindi" : null} state={alert}
                        setState={setAlert}/> : null}
        {confirmationModalForEdit ? <ConfirmationModal title={"Kupon Bilgisi Düzenleme"}
                                                       description={"Düzenleme yapmak istediğinize emin misinz ?"}
                                                       state={confirmationModalForEdit}
                                                       setState={setConfirmationModalForEdit} setReason={setReason}
                                                       reason={"edit"}/> : null}
        {confirmationModalForDelete ?
            <ConfirmationModal title={"Kupon Bilgisi Silme"} description={"Silmek istediğinize emin misinz ?"}
                               state={confirmationModalForDelete} setState={setConfirmationModalForDelete}
                               setReason={setReason} reason={"delete"}/> : null}
        <div className="row">
            <div className="col-lg-12">
                <div className="content-wrapper">
                    <div className="row page">
                        <div className="col-lg-12 pageTitle">
                            <h1>Kuponlar</h1>
                        </div>
                        <div className="col-lg-12 operatorAddButton">
                            <button className="btn btn-primary" onClick={() => redirectToAddOperatorPage()}>Kupon Ekle
                            </button>
                        </div>
                        <div className="col-lg-12 pageContent">
                            <Table titles={titles}
                                   values={values}
                                   valueTotalCount={valuesCount}
                                   keys={keys}
                                   setSort={setSort}
                                   setStartDate={setStartDate}
                                   setFinishDate={setFinishDate}
                                   searchDate={searchDate}
                                   page={page}
                                   setPage={setPage}
                                   setSearchText={setSearchCoupon}
                                   searchParam={"Kod"}
                                   functions={functions}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default CouponList;