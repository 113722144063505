import { useSelector } from "react-redux";
import {useNavigate } from 'react-router-dom';

const Header = () => {
  const userInfos = useSelector((state) => state.userInfos);
  const navigate = useNavigate();

  const openSettingsPage = () => {
    navigate('/ayarlar');
  }
  return (
    <nav className="main-header navbar navbar-expand navbar-white navbar-light">
      <ul className="navbar-nav">

      </ul>

      {/* <!-- Right navbar links --> */}
      <ul className="navbar-nav ml-auto">
        <li className="nav-item">
          <a className="nav-link" data-widget="fullscreen" href="#" role="button">
            <i className="fa fa-expand-arrows-alt"></i>
          </a>
        </li>
        {userInfos && userInfos.user_role && userInfos.user_role.name  == "superAdmin" ? 
        <li className="nav-item">
          <a className="nav-link" data-widget="control-sidebar" data-slide="true" href="#" role="button" onClick={() => openSettingsPage()}>
            <i className="fa fa-gear"></i>
          </a>
        </li> : null}
      </ul>
    </nav>
  )
}

export default Header;