import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { apiUrl } from "../../../config";
import Alert from "../../Partials/Modals/Alert/alert";
import ConfirmationModal from "../../Partials/Modals/Confirmation/confirmation";
import Table from "../../Partials/Table/table";

const OperatorTypes = () => {
    const [page,setPage] = useState(1);
    const [values,setValues] = useState([]);
    const [reason,setReason] = useState("");
    const [searchText,setSearchText] = useState("");
    const [confirmationModalForEdit,setConfirmationModalForEdit] = useState();
    const [confirmationModalForDelete,setConfirmationModalForDelete] = useState();
    const [alert,setAlert] = useState(false);
    const token = useSelector((state) => state.token);
    const userInfos = useSelector((state) => state.userInfos);
    const navigate = useNavigate();

    const titles = ["id","Operator İsmi","İşlemler"];
    const keys = ["id","attributes.name","action"];

    useEffect(() => {
        async function fetchData() {
            let url = "/api/user-roles?filters[name][$ne]=null&pagination[start]="+ ((page - 1) * 10) +"&pagination[limit]=10";
            if(searchText !== ""){
                url = "/api/user-roles?filters[name][$contains]=" + searchText+ "&pagination[start]="+ ((page - 1) * 10) +"&pagination[limit]=10";
            }
            const response = await fetch(apiUrl + url,
            {
                headers: {
                    authorization: `Bearer ${token}`,
                }
            });
            setValues(await response.json());       
        }
        fetchData();
    },[page,confirmationModalForDelete,searchText]);

    const editOperatorType = (row) => {
        setConfirmationModalForEdit(row);
    }

    const deleteOperatorType = (row) => {
        setConfirmationModalForDelete(row);
    }

    let functions = [];
    if(userInfos && userInfos.admin_access_control && userInfos.admin_access_control.controls && userInfos.admin_access_control.controls["operatortype-edit"] && userInfos.admin_access_control.controls["operatortype-edit"].checked){
        functions.push({function: editOperatorType, tooltip: "Düzenle",icon: "fa fa-pencil-square-o"});
    }
    if(userInfos && userInfos.admin_access_control && userInfos.admin_access_control.controls && userInfos.admin_access_control.controls["operatortype-delete"] && userInfos.admin_access_control.controls["operatortype-delete"].checked){
        functions.push({function: deleteOperatorType, tooltip: "Sil",icon: "fa fa-trash"});
    }

    const redirectToAddOperatorTypePage = () => {
        navigate("/operator-tipi-ekle");
    }

    useEffect(() => {
        if(reason == "edit"){
            navigate('/operator-tipi-duzenleme/' + confirmationModalForEdit.id);
        }else if(reason == "delete"){
            async function fetchData() {
                const data = await fetch(apiUrl+'/api/user-roles/' + confirmationModalForDelete.id, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        authorization: `Bearer ${token}`,
                    }
                });
                let result = await data.json();
                if(result && result.data && result.data.id){
                    setConfirmationModalForDelete();
                    setAlert(true);
                }
            }
            fetchData();
        }
    },[reason]);

    return (<>
        {alert ? <Alert title={"Operator Tipi Durumu"} description={reason == "delete" ? "Operator Tipi Silindi" : "Operator Tipi Düzenlendi."} state={alert} setState={setAlert}/> : null}
        {confirmationModalForEdit ? <ConfirmationModal title={"Operator Tip Bilgisi Düzenleme"} description={"Düzenleme yapmak istediğinize emin misinz ?"} state={confirmationModalForEdit} setState={setConfirmationModalForEdit} setReason={setReason} reason={"edit"}/> : null}
        {confirmationModalForDelete ? <ConfirmationModal title={"Operator Tip Bilgisi Silme"} description={"Silmek istediğinize emin misinz ?"} state={confirmationModalForDelete} setState={setConfirmationModalForDelete} setReason={setReason} reason={"delete"}/> : null}
        <div className="row">
            <div className="col-lg-12">
                <div className="content-wrapper">
                    <div className="row page">
                        <div className="col-lg-12 pageTitle">
                            <h1>Operatör Tipleri</h1>
                        </div>
                        <div className="col-lg-12 operatorAddButton">
                            <button className="btn btn-primary" onClick={() => redirectToAddOperatorTypePage()}>Operatör Tipi Ekle</button>
                        </div>
                        <div className="col-lg-12 pageContent">
                            <Table titles={titles}  values={values && values.data ? values.data.filter((item) => item.attributes.name !== "superAdmin") : []} valueTotalCount={values.totalCount} keys={keys} page={page} setPage={setPage} functions={functions} setSearchText={setSearchText}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default OperatorTypes;