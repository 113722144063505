const ForgotPassword = ({emailRef,sendMail}) => {
    return (
        <div className="login-box-body">
            <p className="login-box-msg">Emailinizi giriniz.</p>
            <form>
                <div className="form-group has-feedback">
                    <input type="email" className="form-control" placeholder="Email" ref={emailRef} />
                    <span className="glyphicon glyphicon-envelope form-control-feedback"></span>
                </div>
                <div className="row">
                    <div className="col-xs-4">
                        <button type="button" className="btn btn-primary btn-block btn-flat" onClick={() => sendMail()}>Mail Gönder</button>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default ForgotPassword;