import {cn} from "../../../lib/utils";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {apiUrl} from "../../../config";
import {useSelector} from "react-redux";
import Alert from "../../Partials/Modals/Alert/alert";
import Calendar from "react-date-range/dist/components/Calendar";
import * as locales from 'react-date-range/dist/locale';

const Coupon = ({blog_id, usage_limit, code, expiration_date, discount_rate, discount_amount}) => {
    const navigate = useNavigate();
    const token = useSelector((state) => state.token);
    if (expiration_date) {
        expiration_date = new Date(expiration_date * 1000);
    }

    const [blogId, setBlogId] = useState(blog_id || null);
    const [adminAccessTypeExist, setAdminAccessTypeExist] = useState();
    const [usageLimit, setUsageLimit] = useState(usage_limit || 1);
    const [couponCode, setCouponCode] = useState(code || "");
    const [expirationDate, setExpirationDate] = useState(expiration_date || null);
    const [discountRate, setDiscountRate] = useState(discount_rate || 0);
    const [discountAmount, setDiscountAmount] = useState(discount_amount || 0);
    const [countdown, setCountdown] = useState(3);
    const [countdownStart, setCountdownStart] = useState(false);


    useEffect(() => {
        let timer;

        if (blogId && expiration_date) {
            setLimitTime(true);
        }

        if (blogId && discountAmount > discountRate) {
            setDiscountType(DiscountType.Price);
        }

        if (countdownStart) {
            // Geri sayımı başlat
            timer = setInterval(() => {
                setCountdown(prevCountdown => {
                    if (prevCountdown === 1) {
                        clearInterval(timer); // Geri sayım sona erdiğinde timer'ı durdur
                        navigate("/kuponlar"); // Yönlendirme yap
                    }
                    return prevCountdown - 1;
                });
            }, 1000); // Her 1 saniyede bir geri sayımı güncelle
        }

        return () => clearInterval(timer); // Component unmount olduğunda timer'ı temizle
    }, [adminAccessTypeExist, navigate]);

    // toggle settings for time section
    const [limitTime, setLimitTime] = useState(false);


    const DiscountType = {
        Percentage: "percentage", Price: "price",
    };

    // toggle settings for discount
    const [discountType, setDiscountType] = useState(DiscountType.Percentage);

    const dateOptions = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'};

    //#region SAVE FUNC
    const onClickHandler = () => {
        if (couponCode.length < 6 || couponCode.length > 30) {
            setAdminAccessTypeExist({
                title: "Kupon Kodu", description: "Kupon kodunuz minimum 6, maksimum 30 karakter olabilir."
            });
            return;
        }
        let requestBody = {}
        requestBody.usage_limit = usageLimit;
        requestBody.code = couponCode;
        requestBody.expiration_date = null;

        if(limitTime){
            const couponExpirationDateEpoch = Math.floor(expirationDate.getTime() / 1000);
            requestBody.expiration_date = couponExpirationDateEpoch;
        }


        if (discountType === DiscountType.Percentage) {
            setDiscountAmount(0);
            requestBody.discount_rate = discountRate;
            requestBody.discount_amount = null;

        } else {
            setDiscountRate(0)
            requestBody.discount_amount = discountAmount;
            requestBody.discount_rate = null;
        }

        //update
        if(blogId){
            couponRequest(requestBody, `/api/coupon-codes/${blogId}`, 'PUT').then((response) => {
                if (response.data) {
                    setCountdownStart(true);
                    setAdminAccessTypeExist({
                        title: "Kupon Başarıyla Güncellendi", description: `Güncellenen kod: ${response.data.attributes.code}.`
                    });
                    setCountdown(3);
                } else {
                    setAdminAccessTypeExist({
                        title: "HATA!", description: `Kupon güncellenirken hata oldu. Lütfen daha sonra tekrar deneyin.`
                    });
                }
            })
        }else { //create
            // bugunden geri tarih girilirse diye uyarı
            if (limitTime) {
                if (expirationDate.setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)) {
                    setAdminAccessTypeExist({
                        title: "Tarih Bilgisini Kontrol Edin", description: "Tarihi geçmiş kupon oluşturamazsınız!"
                    });
                    return;
                }
            }

            couponRequest(requestBody, '/api/coupon-codes', 'POST').then((response) => {
                if (response.data) {
                    setCountdownStart(true);
                    setAdminAccessTypeExist({
                        title: "Kupon Başarıyla Oluşturuldu", description: `Oluşan kod: ${response.data.attributes.code}.`
                    });
                    setCountdown(3);
                } else {
                    setAdminAccessTypeExist({
                        title: "HATA!", description: `Kupon oluşturulurken hata oldu. Lütfen daha sonra tekrar deneyin.`
                    });
                }
            })
        }
    }


    //#region REQUEST
    const couponRequest = async (body, url, method) => {
        const data = await fetch(apiUrl + url, {
            method: method, headers: {
                'Content-Type': 'application/json', authorization: `Bearer ${token}`,
            }, body: JSON.stringify({
                data: body
            })
        });
        return await data.json();
    }
    //#endregion

    //#endregion

    return (
        <>
            {adminAccessTypeExist ?
                <Alert title={adminAccessTypeExist.title}
                       description={`${adminAccessTypeExist.description}  ${countdownStart === true ? (`${countdown} saniye içinde kuponlarım sayfasına yönlendiriliceksiniz.`) : ""} `}
                       state={adminAccessTypeExist} setState={setAdminAccessTypeExist}/> : null}


            <form className="form-horizontal col-sm-10" onSubmit={(e) => {
                e.preventDefault();
                onClickHandler();
            }}>
                <div className="box-body">
                    {/*#region DATE*/}
                    <div className="form-group">
                        <label className="col-sm-2 control-label">Süre</label>

                        <div>
                            <div className="btn-group" role="group"
                                 aria-label="Basic outlined example">

                                <Calendar locale={locales['tr']} onChange={i => {
                                    setLimitTime(true)
                                    setExpirationDate(i)
                                }} date={expirationDate}/>

                                <button name="limitless-btn" type="button"
                                        className={cn("btn btn-outline-primary", !limitTime ? "active" : "")}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            if (limitTime) setLimitTime(!limitTime);
                                            setExpirationDate(null);
                                        }}>Sınırsız
                                </button>
                            </div>
                            {expirationDate && (<span
                                className="ml-4 badge text-bg-primary">{expirationDate.toLocaleDateString('tr-TR', dateOptions)}</span>)}
                        </div>

                    </div>
                    {/*#endregion*/}

                    {/*#region COUPON CODE */}
                    <div className="form-group">
                        <label className="col-sm-2 control-label">Kupon Kodu</label>
                        <div className="input-group mb-3 col-sm-6">
                            <input type="text" className="form-control"
                                   min={6}
                                   max={30}
                                   aria-describedby="button-addon2" value={couponCode}
                                   onChange={(e) => {
                                       let value = e.target.value;
                                       // Küçük harfleri büyük harfe çevir, boşlukları ve özel karakterleri kaldır, sadece tire (-) karakterine izin ver
                                       value = value.toUpperCase()
                                           .replace(/[^A-Z0-9\-]/g, '') // Sadece büyük harf, rakam ve tire karakterine izin ver
                                           .replace(/\s/g, ''); // Boşlukları kaldır
                                       setCouponCode(value);
                                   }}/>
                        </div>
                    </div>
                    {/*#endregion*/}

                    {/*#region NUMBER OF USES*/}
                    <div className="form-group">
                        <label className="col-sm-2 control-label">Kullanım Sayısı</label>
                        <div className="input-group mb-3 col-sm-6">
                            <input type="number" className="form-control"
                                   min={1}
                                   aria-describedby="button-addon2" value={usageLimit}
                                   onChange={(e) => {
                                       const value = parseInt(e.target.value, 10);
                                       if (value >= 1 || e.target.value === '') {
                                           setUsageLimit(value);
                                       }
                                   }}/>
                        </div>
                    </div>
                    {/*#endregion*/}

                    {/*#region DISCOUNT*/}
                    <div className="form-group">
                        <label className="col-sm-2 control-label">İndirim</label>
                        <div className="row">
                            <div className="input-group mb-3 col-sm-6">
                                <input
                                    value={discountRate}
                                    max={100}
                                    onChange={(e) => {
                                        const value = parseInt(e.target.value, 10);
                                        if (value >= 1 || e.target.value === '') {
                                            setDiscountRate(value)
                                        }
                                    }}
                                    name="percentage"
                                    onClick={() => setDiscountType(DiscountType.Percentage)}
                                    type="number"
                                    className={cn("form-control", discountType === DiscountType.Price ? "bg-secondary" : "")}/>
                                <span className="input-group-text" id="oran-span">Oran (%)</span>
                            </div>

                            <div className="input-group mb-3 col-sm-6">
                                <input
                                    value={discountAmount}
                                    onChange={(e) => {
                                        const value = parseInt(e.target.value, 10);
                                        if (value >= 1 || e.target.value === '') {
                                            setDiscountAmount(value)
                                        }
                                    }}
                                    name="price"
                                    type="number"
                                    className={cn("form-control", discountType === DiscountType.Percentage ? "bg-secondary" : "")}
                                    onClick={() => setDiscountType(DiscountType.Price)}/>
                                <span className="input-group-text" id="basic-addon2">Fiyat ₺</span>
                            </div>
                        </div>

                    </div>
                    {/*#endregion*/}
                </div>
                <div className="box-footer">
                    <div className="row">
                        <div className="col-lg-3">
                            <button type="button" className="btn btn-default mr-4"
                                    onClick={() => navigate("/dashboard")}>Vazgeç
                            </button>
                            <button type="submit" className="btn btn-info">Kaydet</button>
                        </div>
                    </div>
                </div>
            </form>
        </>
    )
}

export default Coupon;