import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import {createStore,applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import reducer from "./redux/reducer/index";

import Home from './components/pages/Homepage/home';
import Login from './components/pages/Login/login';
import Register from './components/pages/Register/register';
import Layout from './components/Partials/Layout/layout';
import Users from './components/pages/Users/users';
import UserEdit from './components/pages/Users/userEdit';
import ResetPassword from './components/pages/ResetPassword/resetPassword';
import Operators from './components/pages/Operators/operator';
import AddOperator from './components/pages/Operators/addOperator';
import OperatorTypes from './components/pages/Operators/operatorTypes';
import AddOperatorType from './components/pages/Operators/addOperatorType';
import OperatorEdit from './components/pages/Operators/operatorEdit';
import OperatorTypeEdit from './components/pages/Operators/operatorTypeEdit';
import Tickets from './components/pages/Tickets/tickets';
import TicketAnswer from './components/pages/Tickets/ticketAnswer';
import Settings from './components/pages/Settings/settings';
import Payments from './components/pages/Payments/payments';
import Accepts from './components/pages/Accepts/accepts';
import AcceptDetail from './components/pages/Accepts/acceptDetail';
import Deliveries from './components/pages/Deliveries/deliveries';
import DeliveryDetail from './components/pages/Deliveries/deliveryDetail';

import 'bootstrap/dist/css/bootstrap.css';
import './assets/css/adminlte.min.css';
import './assets/css/font-awesome.css';
import './assets/css/ionicons.css';
import './assets/css/custom.css';
import Rentals from './components/pages/Rentals/rentals';
import ShowRental from './components/pages/Rentals/rental';
import Chats from './components/pages/Chats/userChats';
import Licances from './components/pages/Licances/licances';
import Licance from './components/pages/Licances/licance';
import Chat from './components/pages/Chats/chat';
import CreateCoupon from "./components/pages/Coupon/createCoupon";
import CouponList from "./components/pages/Coupon/couponList";
import EditCoupon from "./components/pages/Coupon/editCoupon";


const root = ReactDOM.createRoot(document.getElementById('root'));

  const storeWithStorage = createStore(
    reducer,
    applyMiddleware(thunk)
  );
  
root.render(
  <Provider store={storeWithStorage}>
    <BrowserRouter>
      <Layout>
        <Routes>
          <Route path="/dashboard" element={<Home />} />
          <Route exact path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/kullanicilar" element={<Users />} />
          <Route path="/kullanici-duzenleme/:id" element={<UserEdit />} />
          <Route path="/sifre-guncelleme/:code" element={<ResetPassword />} />
          <Route path="/operatorler" element={<Operators />} />
          <Route path="/operator-ekle" element={<AddOperator />} />
          <Route path="/operator-duzenleme/:id" element={<OperatorEdit />} />
          <Route path="/operator-tipleri" element={<OperatorTypes />} />
          <Route path="/operator-tipi-ekle" element={<AddOperatorType />} />
          <Route path="/operator-tipi-duzenleme/:id" element={<OperatorTypeEdit />} />
          <Route path="/ticketlar" element={<Tickets />} />
          <Route path="/ticket-cevaplama/:id" element={<TicketAnswer />} />
          <Route path="/odemeler" element={<Payments />} />
          <Route path="/arac-onaylari" element={<Accepts />} />
          <Route path="/ehliyet-onaylari" element={<Licances/>} />
          <Route path="/teslimatlar" element={<Deliveries />} />
          <Route path="/chatler" element={<Chats />} />
          <Route path="/chat-detay/:id" element={<Chat />} />
          <Route path="/ehliyet-onay/:id" element={<Licance />} />
          <Route path="/teslimat-goruntuleme/:id" element={<DeliveryDetail />} />
          <Route path="/kiraliklar" element={<Rentals />} />
          <Route path="/kiralama-goruntuleme/:id" element={<ShowRental />} />
          <Route path="/onay-goruntuleme/:id" element={<AcceptDetail />} />
          <Route path="/ayarlar" element={<Settings />} />
          <Route path="/kupon-olustur" element={<CreateCoupon />} />
          <Route path="/kuponlar" element={<CouponList />} />
          <Route path="/kupon-duzenleme/:id" element={<EditCoupon />} />
        </Routes>
      </Layout>
    </BrowserRouter>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
