import {useState} from "react";

const ConfirmationModal = ({title, description, state, setState, setReason, reason, setDenyReason}) => {
    const [selectedReason, setSelectedReason] = useState('');
    const [other, setOther] = useState(false);

    const handleSelectChange = (event) => {
        const selectedValue = event.target.value;
        setSelectedReason(selectedValue);
        setDenyReason(selectedValue)
        setOther(selectedValue === 'Diğer');
    };

    const handleInputChange = (event) => {
        const selectedValue = event.target.value;
        setDenyReason(selectedValue)
    };

    if (reason == 'show') {
        return setReason(reason)
    }

    return (
        <div className={state ? "modal fade show" : "modal fade"} id="exampleModal" tabIndex="-1" role="dialog"
             aria-labelledby="exampleModalLabel" aria-hidden="true" style={{display: state ? "block" : "none"}}>
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">{title}</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                onClick={() => setState(false)}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {description}
                        {reason === "licance-deny" && (
                            <div>
                                <select
                                    value={selectedReason}
                                    onChange={handleSelectChange}
                                    className="form-select"
                                    aria-label="Operator Tipi"
                                >
                                    <option>Bulanık Fotoğraf</option>
                                    <option>Geçersiz Belge</option>
                                    <option>Kişi-Ehliyet Uyuşmazlığı</option>
                                    <option>Diğer</option>
                                </select>
                                {other && <textarea onChange={handleInputChange}
                                                    placeholder="Reddedilme sebebini giriniz" type="text"
                                                    className="form-control"/>
                                }
                            </div>
                        )}
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal"
                                onClick={() => setState(false)}>Kapat
                        </button>
                      <button type="button" className="btn btn-primary"
                                                         onClick={() => setReason(reason)}>Onaylıyorum</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ConfirmationModal;
