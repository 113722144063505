import { useEffect, useState } from "react";
import Table from "../../Partials/Table/table";
import { apiUrl } from "../../../config";
import { useSelector } from "react-redux";

const Payments = () => {
    const [pageName,setPageName] = useState("payments");
    const [page,setPage] = useState(1);
    const [values,setValues] = useState([]);
    const [sort,setSort] = useState(1);
    const [searchText,setSearchText] = useState("");
    const token = useSelector((state) => state.token);

    const titles = ["id","Kullanıcı Adı","Onaylayan","Ödeme Tarihi","Onaylanma Tarihi"];
    const keys = ["id","attributes.user.data.attributes.username","attributes.approve_user.data.attributes.username","attributes.payment_date","attributes.approve_date"];

    useEffect(() => {
        async function fetchData() {
            const srt = sort === 1 ? 'sort=id:desc' : 'sort=id:asc';
            let url = "/api/payments?"+srt+"&populate=user,approve_user&pagination[start]="+ ((page - 1) * 10) +"&pagination[limit]=10";
            if(searchText !== ""){
                url = "/api/payments?"+srt+"&populate=user,approve_user&filters[user][username][$containsi]=" + searchText + "&pagination[start]="+ ((page - 1) * 10) +"&pagination[limit]=10";
            }
            const response = await fetch(apiUrl + url,
            {
                headers: {
                    authorization: `Bearer ${token}`,
                }
            });
            let result = await response.json();
            result.data = result.data.filter((item) => item.attributes.user !== null && item.attributes.approve_user !== null);
            setValues(result);       
        }
        fetchData();
    },[page,searchText]);

    let functions = [];

    return(<>
        <div className="row">
            <div className="col-lg-12">
                <div className="content-wrapper">
                    <div className="row page">
                        <div className="col-lg-12 pageTitle">
                            <h1>Ödemeler</h1>
                        </div>
                        <div className="col-lg-12 pageContent">
                            <Table pageName={pageName} titles={titles}  values={values.data} valueTotalCount={values.totalCount} keys={keys} page={page} setPage={setPage} functions={functions} setSearchText={setSearchText}  setSort={setSort} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default Payments;