import Coupon from "./coupon";

const CreateCoupon = () => {
    return (<>
        <div className="row">
            <div className="col-lg-12">
                <div className="content-wrapper">
                    <div className="row page">
                        <div className="col-lg-12 pageTitle">
                            <h1>Kupon Oluşturma</h1>
                        </div>
                        <div className="col-lg-12 pageContent">
                            <div className="box box-info">
                                <Coupon/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default CreateCoupon;