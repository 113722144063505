import { useEffect, useState } from "react";
import Table from "../../Partials/Table/table";
import { apiUrl } from "../../../config";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ConfirmationModal from "../../Partials/Modals/Confirmation/confirmation";
import Alert from "../../Partials/Modals/Alert/alert";

const Accepts = () => {
    const [pageName,setPageName] = useState("vehicle-accepts");
    const [page,setPage] = useState(1);
    const [values,setValues] = useState([]);
    const [reason,setReason] = useState("");
    const [searchText,setSearchText] = useState("");
    const [sort,setSort] = useState(1);
    const [confirmationModalForAccept,setConfirmationModalForAccept] = useState();
    const [confirmationModalForDecline,setConfirmationModalForDecline] = useState();
    const [confirmationModalForShow,setConfirmationModalForShow] = useState();
    const [confirmationModalForDelete,setConfirmationModalForDelete] = useState();
    const now = new Date();
    const today = new Date(now);
    today.setHours(0, 0, 0, 0);
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); 
    const day = String(today.getDate()).padStart(2, '0'); 
    const todayToIsoString = `${year}-${month}-${day}T00:00:00.000Z`;
    
    const [startDate, setStartDate] = useState(todayToIsoString);
    const [finishDate, setFinishDate] = useState(todayToIsoString);
    const [searchD, searchDate] = useState(false);
    const [dateParam, setDateParam] = useState("Oluşturulma");
    const [searchParam, setSearchParam] = useState("Araç Plakası");

    const [alert,setAlert] = useState(false);
    const token = useSelector((state) => state.token);
    const userInfos = useSelector((state) => state.userInfos);
    const navigate = useNavigate();

    const titles = ["id","Kullanıcı Adı","Marka","Model","Plaka","Eklenme Tarihi","İşlemler"];
    const keys = ["id","attributes.user.data.attributes.username","attributes.vehicle_brand.data.attributes.name","attributes.vehicle_model.data.attributes.name","attributes.plate_number","attributes.createdAt","action"];

    useEffect(() => {
        async function fetchData() {
            console.log({startDate},{finishDate})
            let srt = sort == 1 ? 'sort=id:desc' : 'sort=id:asc';
            if (searchD) {
                srt += `&filters[$and][0][createdAt][$lt]=${finishDate}&filters[$and][1][createdAt][$gt]=${startDate}`
            }
            let url = "/api/vehicle-details?"+srt+"&populate=user,vehicle_brand,vehicle_model,status&filters[status][id]=2&pagination[start]="+ ((page - 1) * 10) +"&pagination[limit]=10";
            if(searchText !== ""){
                url = "/api/vehicle-details?"+srt+"&populate=user,vehicle_brand,vehicle_model,status&filters[status][id]=2&filters[plate_number][$containsi]=" + searchText + "&pagination[start]="+ ((page - 1) * 10) +"&pagination[limit]=10";
            }
            const response = await fetch(apiUrl + url,
            {
                headers: {
                    authorization: `Bearer ${token}`,
                }
            });
            let result = await response.json();
            if (result.data) {
                result.data = result.data.filter((item) => item.attributes.user && item.attributes.user.data !== null && item.attributes.vehicle_model && item.attributes.vehicle_model.data !== null && item.attributes.vehicle_brand && item.attributes.vehicle_brand.data !== null);
            }
            console.log(result)
            setValues(result);       
        }
        fetchData();
    },[page,confirmationModalForDelete,searchText,confirmationModalForAccept,
        confirmationModalForDecline,sort,
        startDate,finishDate,searchD
    ]);

    const changeStatusForAccept = async(row) => {
        setConfirmationModalForAccept(row);
    }

    const changeStatusForDecline = async(row) => {
        setConfirmationModalForDecline(row);
    }

    const showAccepts = (row) => {
        console.log(row)
        setConfirmationModalForShow(row);
    }

    const deleteAccepts = (row) => {
        setConfirmationModalForDelete(row);
    }

    let functions = [];
    if(userInfos && userInfos.admin_access_control && userInfos.admin_access_control.controls && userInfos.admin_access_control.controls["accepts-accept"] && userInfos.admin_access_control.controls["accepts-accept"].checked){
        functions.push({function: changeStatusForAccept, tooltip: "Onayla", icon: "fa fa-check"});
    }
    if(userInfos && userInfos.admin_access_control && userInfos.admin_access_control.controls && userInfos.admin_access_control.controls["accepts-decline"] && userInfos.admin_access_control.controls["accepts-decline"].checked){
        functions.push({function: changeStatusForDecline, tooltip: "Reddet", icon: "fa fa-close"});
    }
    if(userInfos && userInfos.admin_access_control && userInfos.admin_access_control.controls && userInfos.admin_access_control.controls["accepts-edit"] && userInfos.admin_access_control.controls["accepts-edit"].checked){
        functions.push({function: showAccepts, tooltip: "Göster", icon: "fa fa-eye"});
    }
    if(userInfos && userInfos.admin_access_control && userInfos.admin_access_control.controls && userInfos.admin_access_control.controls["accepts-delete"] && userInfos.admin_access_control.controls["accepts-delete"].checked){
        functions.push({function: deleteAccepts, tooltip: "Sil",icon: "fa fa-trash"});
    }

    useEffect(() => {
        if(reason == "accept"){
            async function fetchData() {
                const data = await fetch(apiUrl+'/api/vehicle-details/' + confirmationModalForAccept.id, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        data: {
                            status: 1
                        }
                    })
                });
                let result = await data.json();
                if(result && result.data.id){
                    setConfirmationModalForAccept();
                    setAlert(true);
                }
            }
            fetchData();
        }if(reason == "decline"){
            async function fetchData() {
                const data = await fetch(apiUrl+'/api/vehicle-details/' + confirmationModalForDecline.id + "?pagination[page]=" + page + "&pagination[pageSize]=10", {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        data: {
                            status: 3
                        }
                    })
                });
                let result = await data.json();
                if(result && result.data.id){
                    setConfirmationModalForDecline();
                    setAlert(true);
                }
            }
            fetchData();
        }else if(reason == "show"){
            navigate('/onay-goruntuleme/' + confirmationModalForShow.id);
        }else if(reason == "delete"){
            async function fetchData() {
                const data = await fetch(apiUrl+'/api/vehicle-details/' + confirmationModalForDelete.id, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        authorization: `Bearer ${token}`,
                    }
                });
                let result = await data.json();
                if(result && result.data && result.data.id){
                    setConfirmationModalForDelete();
                    setAlert(true);
                }
            }
            fetchData();
        }
    },[reason]);

    return(<>
        {alert ? <Alert title={"Operator Durumu"} description={reason == "delete" ? "Operator Silindi" : "Operator Düzenlendi."} state={alert} setState={setAlert}/> : null}
        {confirmationModalForAccept ? <ConfirmationModal title={"Onaylama"} description={"Onaylıyor musunuz?"} state={confirmationModalForAccept} setState={setConfirmationModalForAccept} setReason={setReason} reason={"accept"}/> : null}
        {confirmationModalForDecline ? <ConfirmationModal title={"Reddetme"} description={"Reddediyor musunuz?"} state={confirmationModalForDecline} setState={setConfirmationModalForDecline} setReason={setReason} reason={"decline"}/> : null}
        {confirmationModalForShow ? <ConfirmationModal title={"Operator Bilgisi Görüntüleme"} description={"Görüntülemek istediğinize emin misinz ?"} state={confirmationModalForShow} setState={setConfirmationModalForShow} setReason={setReason} reason={"show"}/> : null}
        {confirmationModalForDelete ? <ConfirmationModal title={"Operator Bilgisi Silme"} description={"Silmek istediğinize emin misinz ?"} state={confirmationModalForDelete} setState={setConfirmationModalForDelete} setReason={setReason} reason={"delete"}/> : null}
        <div className="row">
            <div className="col-lg-12">
                <div className="content-wrapper">
                    <div className="row page">
                        <div className="col-lg-12 pageTitle">
                            <h1>Onaylar</h1>
                        </div>
                        <div className="col-lg-12 pageContent">
                            <Table pageName={pageName} titles={titles} values={values.data} valueTotalCount={values.totalCount} keys={keys} page={page} setPage={setPage} functions={functions} setSearchText={setSearchText}  setSort={setSort} setStartDate={setStartDate} setFinishDate={setFinishDate} searchDate={searchDate} dateParam={dateParam} searchParam={searchParam} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default Accepts;