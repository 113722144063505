import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Table from "../../Partials/Table/table";
import { apiUrl } from "../../../config";
import { useNavigate } from "react-router-dom";
import ConfirmationModal from "../../Partials/Modals/Confirmation/confirmation";

const Rentals = () => {
    const [pageName,setPageName] = useState("deliveries");
    const [page,setPage] = useState(1);
    const [values,setValues] = useState([]);
    const [searchText,setSearchText] = useState("");
    const [sort,setSort] = useState(1);
    const [filter,setFilter] = useState("");
    const [confirmationModalForShow,setConfirmationModalForShow] = useState();
    const token = useSelector((state) => state.token);
    const userInfos = useSelector((state) => state.userInfos);
    const [reason,setReason] = useState("");
    const navigate = useNavigate();
    const [searchParam, setSearchParam] = useState("Araç Plakası");
    const now = new Date();
    const today = new Date(now);
    today.setHours(0, 0, 0, 0);
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); 
    const day = String(today.getDate()).padStart(2, '0'); 
    const todayToIsoString = `${year}-${month}-${day}T00:00:00.000Z`;
    
    const [startDate, setStartDate] = useState(todayToIsoString);
    const [finishDate, setFinishDate] = useState(todayToIsoString);
    const [searchD, searchDate] = useState(false);
    const [dateParam, setDateParam] = useState("Kiralama Günü");


    const titles = ["id","Araç Sahibi","Kiralayan","Araç Plakası","Başlangıç Tarihi","Bitiş Tarihi","İşlemler"];
    const keys = ["id","attributes.vehicle.data.attributes.user.data.attributes.username","attributes.users_permissions_requested_user.data.attributes.username","attributes.vehicle.data.attributes.plate_number","attributes.start_date","attributes.end_date","action"];
    useEffect(() => {
        async function fetchData() {
            let srt = sort === 1 ? 'sort=id:desc' : 'sort=id:asc';
            if (searchD) {
                srt += `&filters[$and][0][make_delivery_date][$lt]=${finishDate}&filters[$and][1][make_delivery_date][$gt]=${startDate}`
            }            
            let url = ""
            let filterField = ""
            if (filter == 2) {
                filterField += "[$or][0][get_delivery_status][$eq]=0&filters[$or][1][get_delivery_status][$eq]=1&filters[$or][2][get_delivery_status][$eq]=2"
                filterField += "&filters[$or][0][make_delivery_status][$eq]=0&filters[$or][1][make_delivery_status][$eq]=1&filters[$or][2][make_delivery_status][$eq]=2"
            }
            if (filter == 3) {
                filterField += "[$and][0][get_delivery_status][$ne]=0&filters[$and][1][get_delivery_status][$ne]=1&filters[$and][2][get_delivery_status][$ne]=2"
            }
            if (filter == 4) {
                filterField += "[$and][0][make_delivery_status][$ne]=0&filters[$and][1][make_delivery_status][$ne]=1&filters[$and][2][make_delivery_status][$ne]=2"
            }
            if(searchText !== ""){
                if (filter == "" || filter == 1) {
                    url = '/api/vehicle-requests?populate=vehicle.user,vehicle.basket,users_permissions_requested_user&filters[vehicle][plate_number][$containsi]=' + searchText+ '&pagination[start]='+ ((page - 1) * 10) +'&pagination[limit]=10';
                }
            }else {
                url = '/api/vehicle-requests?'+srt+'&filters'+filterField+'&populate=vehicle.user,vehicle.basket,users_permissions_requested_user&pagination[start]='+ ((page - 1) * 10) +'&pagination[limit]=10';
            }
            const response = await fetch(apiUrl + url,
            {
                headers: {
                    authorization: `Bearer ${token}`,
                }
            });
            let result = await response.json();
            setValues(result);       
        }
        fetchData();
    },[page,sort,searchText,filter,startDate,finishDate,searchD]);
    const showRentals = (row) => {
        setConfirmationModalForShow(row);
    }

    let functions = [];
    if(userInfos && userInfos.admin_access_control && userInfos.admin_access_control.controls && userInfos.admin_access_control.controls["rentals-show"] && userInfos.admin_access_control.controls["rentals-show"].checked){
        functions.push({function: showRentals, tooltip: "Göster", icon: "fa fa-eye"});
    }

    useEffect(() => {
        if(reason == "show"){
            navigate('/kiralama-goruntuleme/' + confirmationModalForShow.id);
        }
    },[reason]);
    return(<>
        {confirmationModalForShow ? <ConfirmationModal title={"Kiralama Bilgisi Görüntüleme"} description={"Görüntülemek istediğinize emin misinz ?"} state={confirmationModalForShow} setState={setConfirmationModalForShow} setReason={setReason} reason={"show"}/> : null}
        <div className="row">
            <div className="col-lg-12">
                <div className="content-wrapper">
                    <div className="row page">
                        <div className="col-lg-12 pageTitle">
                            <h1>Teslimatlar</h1>
                        </div>
                        <div className="col-lg-12 pageContent">
                            <Table pageName={pageName} titles={titles} values={values.data} valueTotalCount={values.totalCount} keys={keys} page={page} setPage={setPage} functions={functions} setSearchText={setSearchText} setFilter={setFilter} setSort={setSort} setStartDate={setStartDate} setFinishDate={setFinishDate} searchDate={searchDate} dateParam={dateParam} searchParam={searchParam}  />
                        </div>
                    </div>
                </div>
            </div>
        </div></>
    )
}

export default Rentals;