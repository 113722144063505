import { useEffect, useState, useRef, useCallback } from "react";
import { useSelector } from "react-redux";
import {useNavigate } from 'react-router-dom';
import FsLightbox from "fslightbox-react";
import { apiUrl } from "../../../config";
import Alert from "../../Partials/Modals/Alert/alert";
import ConfirmationModal from "../../Partials/Modals/Confirmation/confirmation";


const Licance = () => {
    const [acceptInfo,setAcceptInfo] = useState();
    const [userName,setUserName] = useState();
    const [frontPhoto,setFrontPhoto] = useState();
    const [backPhoto,setBackPhoto] = useState();
    const [alert,setAlert] = useState(false);
    const [errorAlert,setErrorAlert] = useState();
    const [toggler, setToggler] = useState(false);
    const [toggler2, setToggler2] = useState(false);
    const [confirmationModalForAccept,setConfirmationModalForAccept] = useState();
    const [confirmationModalForDeny,setConfirmationModalForDeny] = useState();
    const [reason,setReason] = useState("");
    const [denyReason,setDenyReason] = useState("Bulanık Fotoğraf");
    const [userId,setUserId] = useState("");
    const [userEmail,setUserEmail] = useState("");



    const token = useSelector((state) => state.token);
    const navigate = useNavigate();
    const paths = window.location.pathname.split("/");

    useEffect(() => {
        if(paths && paths.length > 2){
            async function fetchData() {
                const data = await fetch(apiUrl + '/api/users/' + paths[2] + '?populate=licence_photo_front,licence_photo_back' , {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        authorization: `Bearer ${token}`,
                    }
                });
                const dataJson = await data.json()
                const frontPhotoUrl = dataJson.licence_photo_front !== null ? apiUrl + dataJson.licence_photo_front.url : false
                setFrontPhoto(frontPhotoUrl);
                const backPhotoUrl = dataJson.licence_photo_back !== null ? apiUrl + dataJson.licence_photo_back.url : false
                setBackPhoto(backPhotoUrl);
                const userName = dataJson.name + " " + dataJson.surname
                setUserName(userName)
                setUserId(dataJson.id)
                setUserEmail(dataJson.username)
                console.log(dataJson)
            }
            fetchData();
        }
    },[])

    async function approveOrDenyLicence(status){

        const body = {
            'licence_approvement':status
        }
        console.log({body})
        const data = await fetch(apiUrl + '/api/users/' + userId, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${token}`
            },
            body: JSON.stringify(body)
        });
        let result = await data.json();
        console.log({result})
        if(result.error || result.status == 400 || result.data === null){
            setErrorAlert(status);
        }else{
            const bodyEmail = {
                type: 4,
                receiver:userEmail,
                reason:denyReason,
                userName:userName,
                status:status
            }
            console.log({bodyEmail})
            const emailData = await fetch(apiUrl+'/api/sending-emails',{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    authorization: `Bearer ${token}`
                },
                body: JSON.stringify(bodyEmail)
            });
            const emailResult = await emailData.json()
            setAlert(true);
        }
        setConfirmationModalForAccept(false);
        setConfirmationModalForDeny(false);
    }


    useEffect(() => {
        console.log("use effect calisti")
        if(reason === "accept"){
            approveOrDenyLicence(1)      
        }else if(reason === "licance-deny"){
            approveOrDenyLicence(2)      
        }
        console.log({denyReason})
    },[reason]);

    const cancel = () => {
        navigate('/ehliyet-onaylari');
    }

    const acceptLicance = async (status) => {
        if (status) {
            setConfirmationModalForAccept(true);
        }else{
            setConfirmationModalForDeny(true)
        }
    }
    return (
        <>
            {confirmationModalForAccept ? <ConfirmationModal title={"Onaylama"} description={"Onaylıyor musunuz?"} state={confirmationModalForAccept} setState={setConfirmationModalForAccept} setReason={setReason} reason={"accept"}/> : null}
            {confirmationModalForDeny ? <ConfirmationModal title={"Reddetme"} description={"Reddetmek Sebebi"} state={confirmationModalForDeny} setState={setConfirmationModalForDeny} setReason={setReason} setDenyReason={setDenyReason} reason={"licance-deny"}/> : null}
            {errorAlert ? <Alert title={errorAlert == 1 ? "Ehliyet Onaylama" : "Ehliyet Reddetme"} description={errorAlert == 1 ?  "Ehliyet Onaylama İşlemi Yapılamadı." : "Ehliyet Reddetme İşlemi Yapılamadı."} state={errorAlert} setState={setErrorAlert}/> : null}
            {alert ? <Alert title={"İşlem Yapıldı."} description={"İşlem başarıyla gerçekleştirildi."} state={alert} setState={setAlert}/> : null}

            <div className="row">
                <div className="col-lg-12">
                    <div className="content-wrapper">
                        <div className="row page">
                            <div className="col-lg-12 pageTitle">
                                <h1>Ehliyet Onay</h1>
                            </div>
                            <div className="col-lg-12 pageContent">
                  
                                <div className="box box-info">
                                    <form className="form-horizontal">
                                        <div className="box-body">
                                            <div style={{ float: "left", marginTop:"-30px" }}>
                                                 {userName && <p> <b> Ehliyet Sahibinin Adı Soyadı: </b>{userName}</p>}
                                            </div> 
                                                    {frontPhoto && 
                                                        <label className="col-sm-12 control-label">Ehliyet Ön</label>
                                                    }
                                                    {frontPhoto && 
                                                        <div className="col-lg-3">
                                                            <img src={frontPhoto} disabled style={{width: "100%",height: "200px"}} onClick={() => setToggler2(!toggler2)}/>
                                                        </div>
                                                    }
                                                    {frontPhoto && 
                                                          <FsLightbox toggler={toggler2} sources={[frontPhoto]}/>
                                                    }

                                                    {backPhoto && 
                                                        <label className="col-sm-12 control-label mt-2">Ehliyet Arka</label>
                                                    }
                                                    {backPhoto && 
                                                        <div className="col-lg-3">
                                                            <img src={backPhoto} disabled style={{width: "100%",height: "200px"}} onClick={() => setToggler2(!toggler2)}/>
                                                        </div>
                                                    }
                                                    {backPhoto && 
                                                          <FsLightbox toggler={toggler2} sources={[backPhoto]}/>
                                                    }
                                                    <button type="button" className="btn btn-primary acceptButtons" onClick={() => acceptLicance(1)}>Onayla</button>
                                                    <button type="button" className="ml-2 btn btn-danger acceptButtons" onClick={() => acceptLicance(0)}>Reddet</button>
                                                    <button className="ml-5 btn btn-secondary acceptButtons" onClick={() => cancel()}>
                                                        Geri Dön
                                                    </button>
                                                                
                                        </div>
                                        <div className="box-footer">
                                            <div className="row">
                                
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Licance;