import { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import {useNavigate } from 'react-router-dom';
import FsLightbox from "fslightbox-react";
import { apiUrl } from "../../../config";
import Alert from "../../Partials/Modals/Alert/alert";

const DeliveryDetail = () => {
    const [acceptInfo,setAcceptInfo] = useState();
    const [alert,setAlert] = useState();
    const [errorAlert,setErrorAlert] = useState();
    const [toggler, setToggler] = useState(false);
    const [toggler2, setToggler2] = useState(false);
    const token = useSelector((state) => state.token);
    const navigate = useNavigate();
    const paths = window.location.pathname.split("/");

    useEffect(() => {
        if(paths && paths.length > 2){
            async function fetchData() {
                const data = await fetch(apiUrl + '/api/vehicle-delivery-informations/' + paths[2] + "?populate=submitting_user,vehicle.vehicle_brand,vehicle.vehicle_model,vehicle_images,vehicle,vehicle.user,vehicle.images", {
                    headers: {
                        'Content-Type': 'application/json',
                        authorization: `Bearer ${token}`,
                    }
                });
                setAcceptInfo(await data.json());
            }
            fetchData();
        }

    },[alert,errorAlert])

    const cancel = () => {
        navigate('/teslimatlar');
    }

    const acceptAndDeclineDelivery = async (type) => {
        let body = {};
        if(type == "accept"){
            body = { vehicle_owner_approval: 1};
        }else{
            body = { vehicle_owner_approval: 0};
        }
        const data = await fetch(apiUrl + '/api/vehicle-delivery-informations/' + acceptInfo?.data?.attributes.id, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body)
        });
        let result = await data.json();
        if(result.error && result.error.status == 400){
            setErrorAlert(type);
        }else{
            setAlert(type);
        }
    }

    return (<>
        {alert ? <Alert title={alert == "accept" ? "Teslimat Onaylama" : "Teslimat Reddetme"} description={alert == "accept" ?  "Teslimat Onaylaması Yapıldı." : "Teslimat Onaylaması Reddedildi."} state={alert} setState={setAlert}/> : null}
        {errorAlert ? <Alert title={errorAlert == "accept" ? "Teslimat Onaylama" : "Teslimat Reddetme"} description={errorAlert == "accept" ?  "Teslimat Onaylaması Yapılamadı." : "Teslimat Onaylaması Reddedilemedi."} state={errorAlert} setState={setErrorAlert}/> : null}
        <div className="row">
            <div className="col-lg-12">
                <div className="content-wrapper">
                    <div className="row page">
                        <div className="col-lg-12 pageTitle">
                            <h1>Teslimat Bilgisi Görüntüleme</h1>
                        </div>
                        <div className="col-lg-12 acceptPage">
                            <div className="box box-info">
                                <form className="form-horizontal">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <label htmlFor="inputName" className="col-sm-12 control-label">Araç Sahibi İsmi</label>

                                            <div className="col-sm-12">
                                                <input type="text" className="form-control" id="inputUserName" defaultValue={acceptInfo?.data?.attributes?.vehicle?.data?.attributes?.user?.data?.attributes?.username} disabled/>
                                            </div>
                                        </div>
                                        <div className="col-lg-12" style={{marginTop: 10}}>
                                            <label htmlFor="inputName" className="col-sm-12 control-label">Kiralayan Kişi İsmi</label>

                                            <div className="col-sm-12">
                                                <input type="text" className="form-control" id="inputUserName" defaultValue={acceptInfo?.data?.attributes?.submitting_user?.data?.attributes?.username} disabled/>
                                            </div>
                                        </div>
                                    </div>
                                    <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link active" id="vehiclePhotos-tab" data-bs-toggle="tab" data-bs-target="#vehiclePhotos" type="button" role="tab" aria-controls="vehiclePhotos" aria-selected="true">Araç Fotoğrafları</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="delivery-tab" data-bs-toggle="tab" data-bs-target="#delivery" type="button" role="tab" aria-controls="delivery" aria-selected="false">Araç Teslim Fotoğrafları</button>
                                        </li>
                                    </ul>
                                    <div className="tab-content" id="myTabContent">
                                        <div className="tab-pane fade show active" id="vehiclePhotos" role="tabpanel" aria-labelledby="vehiclePhotos-tab">
                                            <div className="row">
                                                <label className="col-sm-12 control-label">Araç Fotoğrafları</label>
                                                {acceptInfo && acceptInfo.data?.attributes?.vehicle?.data?.attributes?.images?.data?.length > 0 ? 
                                                    acceptInfo?.data?.attributes?.vehicle?.data?.attributes?.images?.data.map((item,index) => 
                                                    <div className="col-lg-4" key={index}>
                                                        <img src={apiUrl + item.attributes.url} style={{width: "100%",height: "200px"}} onClick={() => setToggler(!toggler)}/>
                                                    </div>
                                                    ): null}
                                                    <FsLightbox
                                                        toggler={toggler}
                                                        sources={acceptInfo?.data?.attributes?.vehicle?.data?.attributes?.images?.data?.map((item) =>  {return apiUrl + item.attributes.url})}
                                                    />
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="delivery" role="tabpanel" aria-labelledby="delivery-tab">
                                            <div className="row">
                                                <label className="col-sm-12 control-label">Araç Teslim Fotoğrafları</label>
                                                {acceptInfo && acceptInfo.data?.attributes?.vehicle_images?.data?.length > 0 ? 
                                                    acceptInfo?.data?.attributes?.vehicle_images?.data?.map((item,index) => 
                                                    <div className="col-lg-4" key={index}>
                                                        <img src={apiUrl + item.attributes.url} disabled style={{width: "100%",height: "200px"}} onClick={() => setToggler2(!toggler2)}/>
                                                    </div>): null}
                                                <FsLightbox
                                                    toggler={toggler2}
                                                    sources={acceptInfo?.data?.attributes?.vehicle_images?.data?.map((item) =>  {return apiUrl + item.attributes.url})}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-lg-4">
                                            <label htmlFor="inputBrand" className="col-sm-10 control-label" >Marka</label>

                                            <div className="col-sm-10">
                                                <input type="text" className="form-control" id="inputBrand" defaultValue={acceptInfo?.data?.attributes?.vehicle?.data?.attributes?.vehicle_brand?.data?.attributes?.name} disabled/>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <label htmlFor="inputModel" className="col-sm-10 control-label">Model</label>

                                            <div className="col-sm-10">
                                                <input type="text" className="form-control" id="inputModel"  defaultValue={acceptInfo?.data?.attributes?.vehicle?.data?.attributes?.vehicle_model?.data?.attributes?.name} disabled/>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <label htmlFor="inputModelYear" className="col-sm-10 control-label">Model Yılı</label>

                                            <div className="col-sm-10">
                                                <input type="text" className="form-control" id="inputModelYear" defaultValue={acceptInfo?.data?.attributes?.vehicle?.data?.attributes?.model_year} disabled/>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <label htmlFor="inputPlate" className="col-sm-10 control-label">Plaka</label>

                                            <div className="col-sm-10">
                                                <input type="text" className="form-control" id="inputPlate" defaultValue={ acceptInfo?.data?.attributes?.vehicle?.data?.attributes?.plate_number} disabled/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-3"><button type="button" className="btn btn-default declineButton" onClick={() => cancel()}>Geri Dön</button></div>
                                        <div className="col-lg-7"></div>
                                        <div className="col-lg-1">
                                            <button type="button" className="btn btn-default acceptButton" onClick={() => acceptAndDeclineDelivery("accept")}>Onayla</button>
                                        </div>
                                        <div className="col-lg-1">
                                            <button type="button" className="btn btn-default declineButton" onClick={() => acceptAndDeclineDelivery("decline")}>Reddet</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default DeliveryDetail;