const Breadcrumb = ({ pageTitle, pages }) => {
    return (
        <section className="content-header">
            <h1>
                {pageTitle}
            </h1>
            <ol className="breadcrumb">
                {pages && pages.length > 0 ?
                    pages.map((item, index) =>
                        <li key={index}><a href={item.link}><i className="fa fa-dashboard"></i> {item.name} /</a></li>
                    ) : null}
            </ol>
        </section>
    );
}

export default Breadcrumb;