import { useRef, useState } from "react";
import { apiUrl } from "../../../config";
import { appendData } from "../../../redux/action";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

const ResetPassword = (props) => {
    const password = useRef();
    const password2 = useRef();
    const [showDashboardRedirectButton, setShowDashboardRedirectButton] = useState(false);
    const navigate = useNavigate();

    const userData = async (token) => {
        const response = await fetch(apiUrl + '/users/me?populate=user_roles',
            {
                headers: {
                    authorization: `Bearer ${token}`,
                }
            })
        return await response.json();
    }

    const handleSubmit = async e => {
        e.preventDefault();
        const data = await fetch(apiUrl + '/auth/reset-password', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                password: password.current ? password.current.value : "",
                passwordConfirmation: password2.current ? password2.current.value : "",
                code: window.location.pathname && window.location.pathname.split("/").length > 0 ? window.location.pathname.split("/")[2] : ""
            })
        });
        let result = await data.json();
        const userInfos = await userData(result.jwt);
        props.appendData({ token: result.jwt, userInfos: userInfos });
        navigate("/dashboard");
    }

    return (<>
        <section className="content">
            <div className="login-box">
                <div className="login-logo">
                    <a href="/login">
                        <div className="row">
                            <div className="col-lg-12">
                                <img src="/logo.png" alt="NYON Logo" className="brandLogo" />
                            </div>
                            <div className="col-lg-12">
                                <span className="brand-text font-weight-light">NYON Admin Panel</span>
                            </div>
                        </div>
                    </a>
                </div>
                    <div className="login-box-body">
                        <p className="login-box-msg">Yeni şifrenizi oluşturabilirsiniz.</p>
                        <form onSubmit={handleSubmit}>
                            <div className="form-group has-feedback">
                                <input type="password" className="form-control" placeholder="Şifre" ref={password} />
                                <span className="glyphicon glyphicon-envelope form-control-feedback"></span>
                            </div>
                            <div className="form-group has-feedback">
                                <input type="password" className="form-control" placeholder="Şifre Tekrar" ref={password2} />
                                <span className="glyphicon glyphicon-lock form-control-feedback"></span>
                            </div>
                            {showDashboardRedirectButton ?
                            <div className="form-group has-feedback">
                                <p style={{color: "green"}}>Şifre değiştirme başarılı.</p>
                            </div> :
                            <div className="row">
                                <div className="col-xs-4">
                                    <button type="submit" className="btn btn-primary btn-block btn-flat">Güncelle</button>
                                </div>
                            </div>}
                        </form>
                    </div> 
            </div>
        </section></>
    );
}

const mapDispatchToProps = {
    appendData
}

const mapStateToProps = state => ({
    token: state.token,
    userInfos: state.userInfos
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);