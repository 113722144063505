import { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import {useNavigate } from 'react-router-dom';
import Alert from "../../Partials/Modals/Alert/alert";
import { apiUrl } from "../../../config";

const TicketAnswer = () => {
    const [ticketInfo,setTicketInfo] = useState();
    const [alert,setAlert] = useState(false);
    const answer = useRef();
    const token = useSelector((state) => state.token);
    const navigate = useNavigate();
    const paths = window.location.pathname.split("/");

    useEffect(() => {
        if(paths && paths.length > 2){
            async function fetchData() {
                const data = await fetch(apiUrl + '/api/tickets/' + paths[2] + "?populate=user", {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        authorization: `Bearer ${token}`,
                    }
                });
                setTicketInfo(await data.json());
            }
            fetchData();
        }
    },[])

    const cancel = () => {
        navigate('/ticketlar');
    }

    const edit = async() => {
        const data = await fetch(apiUrl + '/api/tickets/' + paths[2], {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${token}`,
            },
            body:  JSON.stringify({ 
                data: { 
                    "answer": answer.current ? answer.current.value : "",
                }
            })
        });
        const response = await data.json();
        if(response && response.id){
            setAlert(true);
        }
    }
    return (<>
        {alert ? <Alert title={"Ticket Cevaplama"} description={"Ticket bilgileri güncellendi."} state={alert} setState={setAlert}/> : null}
        <div className="row">
            <div className="col-lg-12">
                <div className="content-wrapper">
                    <div className="row page">
                        <div className="col-lg-12 pageTitle">
                            <h1>Kullanıcı Bilgisi Düzenleme</h1>
                        </div>
                        <div className="col-lg-12 pageContent">
                            <div className="box box-info">
                                <form className="form-horizontal">
                                    <div className="box-body">
                                        <div className="form-group disabledClass">
                                            <label htmlFor="inputName" className="col-sm-2 control-label">Kullanıcı Adı</label>

                                            <div className="col-sm-10">
                                                <input type="text" className="form-control" id="inputUserName" placeholder="Kullanıcı Adı" defaultValue={ticketInfo && ticketInfo.data.attributes && ticketInfo.data.attributes.user ? ticketInfo.data.attributes.user.data.attributes.username : ""}/>
                                            </div>
                                        </div>
                                        <div className="form-group disabledClass">
                                            <label htmlFor="inputName" className="col-sm-2 control-label">İsim</label>

                                            <div className="col-sm-10">
                                                <input type="text" className="form-control" id="inputName" placeholder="İsim" defaultValue={ticketInfo && ticketInfo.data.attributes && ticketInfo.data.attributes.user ? ticketInfo.data.attributes.user.data.attributes.name : ""}/>
                                            </div>
                                        </div>
                                        <div className="form-group disabledClass">
                                            <label htmlFor="inputSurname" className="col-sm-2 control-label">Soyisim</label>

                                            <div className="col-sm-10">
                                                <input type="text" className="form-control" id="inputSurname" placeholder="Soyisim" defaultValue={ticketInfo && ticketInfo.data.attributes && ticketInfo.data.attributes.user ? ticketInfo.data.attributes.user.data.attributes.surname : ""}/>
                                            </div>
                                        </div>
                                        <div className="form-group disabledClass">
                                            <label htmlFor="inputEmail" className="col-sm-2 control-label">Email</label>

                                            <div className="col-sm-10">
                                                <input type="email" className="form-control" id="inputEmail" placeholder="Email" defaultValue={ticketInfo && ticketInfo.data.attributes && ticketInfo.data.attributes.user ? ticketInfo.data.attributes.user.data.attributes.email : ""}/>
                                            </div>
                                        </div>
                                        <div className="form-group disabledClass">
                                            <label htmlFor="inputSubject" className="col-sm-2 control-label">Konu</label>

                                            <div className="col-sm-10">
                                                <input type="email" className="form-control" id="inputSubject" placeholder="Konu" defaultValue={ticketInfo && ticketInfo.data && ticketInfo.data.attributes ? ticketInfo.data.attributes.subject : ""}/>
                                            </div>
                                        </div>
                                        <div className="form-group disabledClass">
                                            <label htmlFor="inputMessage" className="col-sm-2 control-label">Message</label>

                                            <div className="col-sm-10">
                                                <textarea cols="2" rows="10" id="inputMessage" placeholder="Email" defaultValue={ticketInfo && ticketInfo.data && ticketInfo.data.attributes ? ticketInfo.data.attributes.message : ""}/>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="inputMessage" className="col-sm-2 control-label">Cevap</label>

                                            <div className="col-sm-10">
                                                <textarea cols="2" rows="10" id="answer" ref={answer}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="box-footer">
                                        <div className="row">
                                            <div className="col-lg-3"><button type="button" className="btn btn-default" onClick={() => cancel()}>Vazgeç</button></div>
                                            <div className="col-lg-6"></div>
                                            <div className="col-lg-3"><button type="button" className="btn btn-info" onClick={() => edit()}>Cevapla</button></div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default TicketAnswer;