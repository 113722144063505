import Coupon from "./coupon";
import {useEffect, useState} from "react";
import {apiUrl} from "../../../config";
import {useSelector} from "react-redux";


const EditCoupon = () => {
    const paths = window.location.pathname.split("/");
    const token = useSelector((state) => state.token);
    const [coupon, setCoupon] = useState(null);
    useEffect(() => {
        if(paths && paths.length > 2) {
            (async () => {
                await fetchData(paths[2]);
            })();
        }


    },[])

    const fetchData = async (id) => {
        const url = `/api/coupon-codes/${id}`
        const response = await fetch(apiUrl+url,{
            headers: {
                authorization: `Bearer ${token}`,
            }
        });
        const result = await response.json()
        if(result.data){
            setCoupon(result.data);
        }
    }

    return (<>
        <div className="row">
            <div className="col-lg-12">
                <div className="content-wrapper">
                    <div className="row page">
                        <div className="col-lg-12 pageTitle">
                            <h1>Kupon Düzenle</h1>
                        </div>
                        <div className="col-lg-12 pageContent">
                            <div className="box box-info">
                                {coupon && coupon.attributes ? (
                                    <Coupon blog_id={coupon.id}
                                            usage_limit={coupon.attributes.usage_limit}
                                            code={coupon.attributes.code}
                                            expiration_date={coupon.attributes.expiration_date}
                                            discount_rate={coupon.attributes.discount_rate}
                                            discount_amount={coupon.attributes.discount_amount}
                                    />
                                ): (
                                    <p>Kupon bilgisi yükleniyor...</p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default EditCoupon;