import { useRef, useState } from 'react';
import { connect } from "react-redux";
import { appendData } from "../../../redux/action";
import { apiUrl } from '../../../config';
import Alert from '../../Partials/Modals/Alert/alert';
import ForgotPassword from '../ForgotPassword/forgotPassword';
import { useNavigate } from 'react-router-dom';

const Login = (props) => {
    const email = useRef(null);
    const password = useRef(null);
    const [forgotScreen, setForgotScreen] = useState(false);
    const [alertMessage, setAlertMessage] = useState();
    const [sendResetLinkAlert, setSendResetLinkAlert] = useState(false);
    const navigate = useNavigate();

    const loginUser = async (credentials) => {
        const data = await fetch(apiUrl + '/api/auth/local', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(credentials)
        });
        return await data.json();
    }

    const userData = async (token) => {
        const response = await fetch(apiUrl + '/api/users/me',
            {
                headers: {
                    authorization: `Bearer ${token}`,
                }
            })
        return await response.json();
    }

    const handleSubmit = async e => {
        e.preventDefault();
        const token = await loginUser({
            identifier: email.current.value,
            password: password.current.value
        });
        
        const userInfos = await userData(token.jwt);
        console.log(userInfos)

        if(userInfos && userInfos.user_role && userInfos.user_role.name && userInfos.user_role.name !== "" && userInfos.user_role.name !== "user"){
            async function fetchData() {
                const response = await fetch(apiUrl +"/api/admin-access-controls?populate=admin_user&filters[admin_user][id][$eq]=" + userInfos.id,
                {
                    headers: {
                        authorization: `Bearer ${token.jwt}`,
                    }
                });
                let result = await response.json();
                if(result.data){
                  userInfos.admin_access_control.controls = result.data[0].attributes.controls
                }
            }
            fetchData();
            props.appendData({ token: token.jwt, userInfos: userInfos });
            navigate("/dashboard");
        }else{
            setAlertMessage("E-posta veya şifre hatalı.");
            navigate("/login");
        }
        
    }

    const sendForgotPasswordMail = async() => {
        const data = await fetch(apiUrl + '/api/auth/forgot-password', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: email.current ? email.current.value : ""
            })
        });
        let result = await data.json();
        if(result.ok){
            email.current.value = "";
            setSendResetLinkAlert(true);
        }
    }
    return (<>
        {sendResetLinkAlert ? <Alert title={"Sıfırlama Linki Gönderme"} description={"Sıfırlama linki gönderildi."} state={sendResetLinkAlert} setState={setSendResetLinkAlert}/> : null}
        <section className="content">
            <div className="login-box">
                <div className="login-logo">
                    <a href="/login">
                        <div className="row">
                            <div className="col-lg-12">
                                <img src="/logo.png" alt="NYON Logo" className="brandLogo" />
                            </div>
                            <div className="col-lg-12">
                                <span className="brand-text font-weight-light">NYON Admin Panel</span>
                            </div>
                        </div>
                    </a>
                </div>
                {!forgotScreen ?
                    <div className="login-box-body">
                        <p className="login-box-msg">Email ve şifrenizle giriş yapabilirsiniz.</p>

                        <form onSubmit={handleSubmit}>
                            <div className="form-group has-feedback">
                                <input type="email" className="form-control" placeholder="Email" ref={email} />
                                <span className="glyphicon glyphicon-envelope form-control-feedback"></span>
                            </div>
                            <div className="form-group has-feedback">
                                <input type="password" className="form-control" placeholder="Şifre" ref={password} />
                                <span className="glyphicon glyphicon-lock form-control-feedback"></span>
                            </div>
                            {alertMessage ? <p className='alertMessage text-center'>{alertMessage}</p> : null}
                            <div className="row">
                                <div className="col-xs-4">
                                    <button type="submit" className="btn btn-primary btn-block btn-flat">Giriş Yap</button>
                                </div>
                            </div>
                        </form>

                        <a href="#" onClick={() => setForgotScreen(true)}>Şifremi unuttum</a><br />

                    </div> :
                    <ForgotPassword emailRef={email} sendMail={sendForgotPasswordMail}/>}
            </div>
        </section></>
    );
}

const mapDispatchToProps = {
    appendData
}

const mapStateToProps = state => ({
    token: state.token,
    userInfos: state.userInfos
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);

