import { useEffect, useRef, useState } from "react";
import {useNavigate } from 'react-router-dom';
import Alert from "../../Partials/Modals/Alert/alert";
import { apiUrl } from "../../../config";
import { useSelector } from "react-redux";
import AccessControl from "../../Partials/accessControl";
import OperatorType from "../../Partials/operatorType";
import Form from "../../Partials/form";

const AddOperator = () => {
    const [alert,setAlert] = useState();
    const [errorAlert,setErrorAlert] = useState();
    const [adminAccessControls,setAdminAccessControls] = useState();
    const [adminAccessTypeExist,setAdminAccessTypeExist] = useState();
    const token = useSelector((state) => state.token);
    const username = useRef();
    const name = useRef();
    const surname = useRef();
    const email = useRef();
    const password = useRef();
    const operatorType = useRef();
    const accessType = useRef();
    const navigate = useNavigate();

    const saveOperator = async() => {
        if(name.current && name.current.value == ""){
            setAdminAccessTypeExist({title: "İsim", description: "İsim alanını giriniz."});
            return;
        }
        if(surname.current && surname.current.value == ""){
            setAdminAccessTypeExist({title: "Soyisim", description: "Soyisim alanını giriniz."});
            return;
        }
        if(email.current && email.current.value == ""){
            setAdminAccessTypeExist({title: "Email", description: "Email alanını giriniz."});
            return;
        }
        if(password.current && password.current.value == ""){
            setAdminAccessTypeExist({title: "Şifre", description: "Şifre alanını giriniz."});
            return;
        }
        if(operatorType.current && operatorType.current.value == "Operator Tipi Seçiniz"){
            setAdminAccessTypeExist({title: "Operator Tipi", description: "Operator tipini seçiniz."});
            return;
        }
        if(accessType.current && accessType.current.value == "Admin Erişim Tipi Seçiniz"){
            setAdminAccessTypeExist({title: "Admin Erişim Tipi", description: "Admin erişim tipini seçiniz."});
            return;
        }
        let adminAccessResult;
        if(adminAccessControls && adminAccessControls.length > 0){
            for(let item of adminAccessControls){
                if(item.id == accessType.current.value){
                    const data = await fetch(apiUrl + '/api/admin-access-controls', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            authorization: `Bearer ${token}`,
                        },
                        body: JSON.stringify({
                            data: {
                                name: name.current ? name.current.value : "",
                                controls: item.attributes.controls
                            }
                        })
                    });
                    adminAccessResult = await data.json();
                }
            }
        }
        if(adminAccessResult){
            const data = await fetch(apiUrl + '/api/auth/local/register', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    name: name.current ? name.current.value : "",
                    surname: surname.current ? surname.current.value : "",
                    username: username.current ? username.current.value: "",
                    email: email.current ? email.current.value : "",
                    password: password.current ? password.current.value : "",
                    user_role: {'connect' : [operatorType.current ? operatorType.current.value : ""] },
                    admin_access_control: {'connect' : [adminAccessResult ? adminAccessResult.data.id : ""] }
                })
            });
            let result = await data.json();
            if(result.error && result.error.status == 400){
                setErrorAlert(result.error.message);
            }else{
                setAlert(true);
            }
        }
    }

    const cancel = () => {
        navigate("/operatorler");
    }



    return (<>
        {alert ? <Alert title={"Operator Ekleme"} description={"Operator Eklemesi Yapıldı."} state={alert} setState={setAlert}/> : null}
        {errorAlert ? <Alert title={"Operator Eklenemedi"} description={errorAlert && typeof errorAlert == 'string' ? errorAlert : "Operator Eklemesi Yapılamadı."} state={errorAlert} setState={setErrorAlert}/> : null}
        {adminAccessTypeExist ? <Alert title={adminAccessTypeExist.title} description={adminAccessTypeExist.description} state={adminAccessTypeExist} setState={setAdminAccessTypeExist}/> : null}
         <div className="row">
            <div className="col-lg-12">
                <div className="content-wrapper">
                    <div className="row page">
                        <div className="col-lg-12 pageTitle">
                            <h1>Operator Ekleme</h1>
                        </div>
                        <div className="col-lg-12 pageContent">
                            <div className="box box-info">
                                <form className="form-horizontal">
                                    <div className="box-body">
                                        <Form keys={["username","name","surname","email","password"]} refs={[username,name,surname,email,password]} texts={["Kullanıcı Adı","İsim","Soyisim","Email","Şifre"]} />
                                        <OperatorType operatorType={operatorType}/>
                                        <AccessControl accessType={accessType} adminAccessControls={adminAccessControls} setAdminAccessControls={setAdminAccessControls}/>
                                    </div>
                                    <div className="box-footer">
                                        <div className="row">
                                            <div className="col-lg-3"><button type="button" className="btn btn-default" onClick={() => cancel()}>Vazgeç</button></div>
                                            <div className="col-lg-4"></div>
                                            <div className="col-lg-3 text-right"><button type="button" className="btn btn-info" onClick={() => saveOperator()}>Kaydet</button></div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default AddOperator;