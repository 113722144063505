import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Table from "../../Partials/Table/table";
import { apiUrl } from "../../../config";
import { useNavigate } from "react-router-dom";
import ConfirmationModal from "../../Partials/Modals/Confirmation/confirmation";

const Chats = () => {
    const [pageName,setPageName] = useState("chats");
    const [page,setPage] = useState(1);
    const [values,setValues] = useState([]);
    const [searchText,setSearchText] = useState("");
    const [sort,setSort] = useState(1);
    const [filter,setFilter] = useState("");
    const [confirmationModalForShow,setConfirmationModalForShow] = useState();
    const token = useSelector((state) => state.token);
    const userInfos = useSelector((state) => state.userInfos);
    const [reason,setReason] = useState("");
    const navigate = useNavigate();

    const now = new Date();
    const today = new Date(now);
    today.setHours(0, 0, 0, 0);
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); 
    const day = String(today.getDate()).padStart(2, '0'); 
    const todayToIsoString = `${year}-${month}-${day}T00:00:00.000Z`;
    
    const [startDate, setStartDate] = useState(todayToIsoString);
    const [finishDate, setFinishDate] = useState(todayToIsoString);
    const [searchD, searchDate] = useState(false);
    const [dateParam, setDateParam] = useState("Araç Plakası");
    const [searchParam, setSearchParam] = useState("Araç Plakası");
    


    const titles = ["id","Araç Sahibi","Müşteri","Araç Plakası","İlk Aktivite","Son Aktivite","İşlemler"];
    const keys = ["id","attributes.vehicle.data.attributes.user.data.attributes.username","attributes.customer.data.attributes.username","attributes.vehicle.data.attributes.plate_number","attributes.createdAt","attributes.updatedAt","action"];
    useEffect(() => {
        async function fetchData() {
            let srt = sort == 1 ? 'sort=id:desc' : 'sort=id:asc';
            if (searchD) {
                srt += `&filters[$and][0][created_at][$lt]=${finishDate}&filters[$and][1][created_at][$gt]=${startDate}`
            }
            if (searchText !== "") {
                srt += '&filters[vehicle][plate_number][$containsi]=' + searchText
            }
            let url = '/api/chats?'+srt+'&populate=vehicle,vehicle.user,owner,customer&pagination[start]='+ ((page - 1) * 10) +'&pagination[limit]=10';

            const response = await fetch(apiUrl + url,
            {
                headers: {
                    authorization: `Bearer ${token}`,
                }
            });
            const responseJson = await response.json()
            console.log(responseJson)
            setValues(responseJson);       
        }
        fetchData();
    },[page,searchText,filter,startDate,sort]);
    
    const showChats = (row) => {
        setConfirmationModalForShow(row);
    }

    let functions = [];
    if(userInfos && userInfos.admin_access_control && userInfos.admin_access_control.controls && userInfos.admin_access_control.controls["chats-show"] && userInfos.admin_access_control.controls["chats-show"].checked){
        functions.push({function: showChats, tooltip: "Göster", icon: "fa fa-eye"});
    }

    useEffect(() => {
        if(reason == "show"){
            navigate('/chat-detay/' + confirmationModalForShow.id);
        }
    },[reason]);
    return(<>
        {confirmationModalForShow ? <ConfirmationModal title={"Kiralama Bilgisi Görüntüleme"} description={"Görüntülemek istediğinize emin misinz ?"} state={confirmationModalForShow} setState={setConfirmationModalForShow} setReason={setReason} reason={"show"}/> : null}
        <div className="row">
            <div className="col-lg-12">
                <div className="content-wrapper">
                    <div className="row page">
                        <div className="col-lg-12 pageTitle">
                            <h1>Chatler</h1>
                        </div>
                        <div className="col-lg-12 pageContent">
                            <Table pageName={pageName}  titles={titles} values={values.data} valueTotalCount={values.totalCount} keys={keys} page={page} setPage={setPage} functions={functions} setSearchText={setSearchText} setFilter={setFilter} setSort={setSort}  setStartDate={setStartDate} setFinishDate={setFinishDate} searchDate={searchDate} dateParam={dateParam} searchParam={searchParam} />
                        </div>
                    </div>
                </div>
            </div>
        </div></>
    )
}

export default Chats;