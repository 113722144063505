import { useEffect, useState, useRef, useCallback } from "react";
import { useSelector } from "react-redux";
import {useNavigate } from 'react-router-dom';
import moment from "moment";
import { apiUrl } from "../../../config";
import ImageViewer from 'react-simple-image-viewer';


const Chat = () => {
    const [chatInfo,setChatInfo] = useState();
    const [messages,setMessages] = useState();
    const [owner,setOwner] = useState();
    const [customer,setCustomer] = useState();


    const answer = useRef();
    const token = useSelector((state) => state.token);
    const navigate = useNavigate();
    const paths = window.location.pathname.split("/");

    useEffect(() => {
        console.log(paths)
        if(paths && paths.length > 2){
            async function fetchData() {
                const data = await fetch(apiUrl + '/api/chats/' + paths[2] + "?populate=vehicle,vehicle.user,owner,customer,messages,messages.user", {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        authorization: `Bearer ${token}`,
                    }
                });
                const dataJson = await data.json()
                console.log("CHAT DATA:",dataJson)
                setChatInfo(dataJson);
                const msgs = dataJson.data.attributes.messages.data ? dataJson.data.attributes.messages.data : []
                setMessages(msgs)
                setCustomer(dataJson.data.attributes.customer)
                setOwner(dataJson.data.attributes.owner)
                console.log(owner,customer)
                console.log(dataJson.data.attributes.owner)
            }
            fetchData();
        }
    },[])
    const cancel = () => {
        navigate('/chatler');
    }

    return (
        <>
            <div className="row">
                <div className="col-lg-12">
                    <div className="content-wrapper">
                        <div className="row page">
                            <div className="col-lg-12 pageTitle">
                                <h1>Chat Detay</h1>
                            </div>
                            <div className="col-lg-12 pageContent">
                            <button style={{ marginTop:"-77px", marginLef:"40px" }} type="button" className="btn btn-default" onClick={() => cancel()}>
                                                        Geri Dön
                            </button>
                                <div className="box box-info">
                                    <form className="form-horizontal">
                                        <div className="box-body">
                                            <div style={{ float: "left", marginTop:"-30px" }}>
                                                 {owner && <p> <b> Araç Sahibi: </b>{owner.data.attributes.username}</p>}
                                            </div> 

                                            <div style={{ float: "right", marginTop:"-30px", width: "66%" }}>
                                                 {owner && <p><b> Müşteri: </b> {customer.data.attributes.username}</p>}
                                            </div> 


                
                                            {messages && messages.length > 0 ? (
                                                messages.map((message, index) => (
                                                    <div
                                                        key={index}
                                                        style={{
                                                            textAlign: message.attributes.user.data.id === customer.data.id ? "right" : "left",
                                                            border: "1px solid #ccc",
                                                            padding: "10px",
                                                            width: "50%"
                                                        }}
                                                    >
                                                        <p>{message.attributes.message}</p> 
                                                        <p>{moment(message.attributes.createdAt).format('DD MM YY HH:mm')}</p>                                                  
                                                    </div>
                                                ))
                                            ) : (
                                                <p>Mesaj bulunmuyor.</p>
                                            )}
                            
                                        </div>
                                        <div className="box-footer">
                                            <div className="row">
                                
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Chat;