import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { apiUrl } from "../../config";

const OperatorType = ({operatorType}) => {
    const [operatorTypes,setOperatorTypes] = useState();
    const token = useSelector((state) => state.token);

    useEffect(() => {
        async function fetchData() {
            const response = await fetch(apiUrl +'/api/user-roles?filters[name][$not]=superAdmin',
            {
                headers: {
                    authorization: `Bearer ${token}`,
                }
            });
            let result = await response.json();
            setOperatorTypes(result.data);       
        }
        fetchData();
    },[]);
    return (
        <div className="form-group">
            <label htmlFor="inputEmail" className="col-sm-2 control-label">Operator Tipi</label>

            <div className="col-sm-10">
                <select className="form-select" aria-label="Operator Tipi" ref={operatorType}>
                    <option defaultValue={"selected"}>Operator Tipi Seçiniz</option>
                    {operatorTypes && operatorTypes.length > 0 ? 
                    operatorTypes.map((item,index) => 
                        <option key={index} value={item.id}>{item.attributes.name}</option>
                    ) : null}
                </select>                                            
            </div>
        </div>
    )
}

export default OperatorType;