import { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import {useNavigate } from 'react-router-dom';
import Alert from "../../Partials/Modals/Alert/alert";
import { apiUrl } from "../../../config";
import { appendData } from "../../../redux/action";
import { connect } from "react-redux";

const UserEdit = () => {
    const [userInfo,setUserInfo] = useState();
    const [alert,setAlert] = useState(false);
    const [sendMailAlert,setSendMailAlert] = useState(false);
    const [sendResetLinkAlert,setSendResetLinkAlert] = useState(false);
    const [sendMailErrorAlert,setSendMailErrorAlert] = useState(false);
    const [showResetLinkButton,setShowResetLinkButton] = useState(false);
    const token = useSelector((state) => state.token);
    const userInfos = useSelector((state) => state.userInfos);
    const username = useRef();
    const name = useRef();
    const surname = useRef();
    const email = useRef();
    const navigate = useNavigate();
    const paths = window.location.pathname.split("/");

    useEffect(() => {
        if(paths && paths.length > 2){
            async function fetchData() {
                const data = await fetch(apiUrl + '/api/users/' + paths[2], {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        authorization: `Bearer ${token}`,
                    }
                });
                setUserInfo(await data.json());
            }
            fetchData();
        }
    },[sendResetLinkAlert])

    const cancel = () => {
        navigate('/kullanicilar');
    }
    const sendActivationMail = async() => {
        const data = await fetch(apiUrl + '/api/auth/send-email-confirmation', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: userInfo.email
            })
        });
        let result = await data.json();
        if(result.sent){
            setSendMailAlert(true);
        }
        if(result.error && result.error.status == 400){
            setSendMailErrorAlert(true);
        }
    }
    const sendPasswordResetLink = async() => {
        const data = await fetch(apiUrl + '/api/auth/forgot-password', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: userInfo.email
            })
        });
        let result = await data.json();
        if(result.ok){
            await editResetLinkDate(Date.now());
            setSendResetLinkAlert(true);
        }
    }
    const edit = async() => {
        const data = await fetch(apiUrl + '/api/users/' + paths[2], {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                username: username.current ? username.current.value : "",
                name: name.current ? name.current.value : "",
                surname: surname.current ? surname.current.value : "",
                email: email.current ? email.current.value : ""
            })
        });
        const response = await data.json();
        if(response && response.id){
            setAlert(true);
        }
    }

    const editResetLinkDate = async(date) => {
        await fetch(apiUrl + '/api/users/' + paths[2], {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                reset_link_send_date: date,
            })
        });
    }

    useEffect(() => {
        if(userInfo && userInfo.reset_link_send_date && userInfo.reset_link_send_date + (3600 * 1000) < Date.now()){
            setShowResetLinkButton(true);
        }else {
            setShowResetLinkButton(false);
        }
    },[userInfo]);

    return (<>
        {alert ? <Alert title={"Kullanıcı Bilgileri Güncelleme"} description={"Kullanıcı bilgileri güncellendi."} state={alert} setState={setAlert}/> : null}
        {sendMailAlert ? <Alert title={"Kullanıcıya Aktivasyon Maili Gönderme"} description={"Kullanıcıya aktivasyon maili gönderildi."} state={sendMailAlert} setState={setSendMailAlert}/> : null}
        {sendResetLinkAlert ? <Alert title={"Kullanıcıya Sıfırlama Linki Gönderme"} description={"Kullanıcıya sıfırlama linki gönderildi."} state={sendResetLinkAlert} setState={setSendResetLinkAlert}/> : null}
        {sendMailErrorAlert ? <Alert title={"Kullanıcıya Aktivasyon Maili Gönderme"} description={"Kullanıcıya aktivasyon maili zaten gönderildi."} state={sendMailErrorAlert} setState={setSendMailErrorAlert}/> : null}
        <div className="row">
            <div className="col-lg-12">
                <div className="content-wrapper">
                    <div className="row page">
                        <div className="col-lg-12 pageTitle">
                            <h1>Kullanıcı Bilgisi Düzenleme</h1>
                        </div>
                        <div className="col-lg-12 pageContent">
                            <div className="box box-info">
                                <form className="form-horizontal">
                                    <div className="box-body">
                                        <div className="form-group">
                                            <label htmlFor="inputName" className="col-sm-2 control-label">Kullanıcı Adı</label>

                                            <div className="col-sm-10">
                                                <input type="text" ref={username} className="form-control" id="inputUserName" placeholder="Kullanıcı Adı" defaultValue={userInfo ? userInfo.username : ""}/>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="inputName" className="col-sm-2 control-label">İsim</label>

                                            <div className="col-sm-10">
                                                <input type="text" ref={name} className="form-control" id="inputName" placeholder="İsim" defaultValue={userInfo ? userInfo.name : ""}/>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="inputSurname" className="col-sm-2 control-label">Soyisim</label>

                                            <div className="col-sm-10">
                                                <input type="text" ref={surname} className="form-control" id="inputSurname" placeholder="Soyisim" defaultValue={userInfo ? userInfo.surname : ""}/>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="inputEmail" className="col-sm-2 control-label">Email</label>

                                            <div className="col-sm-10">
                                                <input type="email" ref={email} className="form-control" id="inputEmail" placeholder="Email" defaultValue={userInfo ? userInfo.email : ""}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="box-footer">
                                        <div className="row">
                                            <div className="col-lg-3"><button type="button" className="btn btn-default" onClick={() => cancel()}>Vazgeç</button></div>
                                            <div className="col-lg-3"><button type="button" className="btn btn-info" onClick={() => sendActivationMail()}>Aktivasyon Maili Gönder</button></div>
                                            <div className="col-lg-3"><button type="button" className={userInfo && userInfo.reset_link_send_date && !showResetLinkButton ? "btn btn-info disabled" : "btn btn-info"} onClick={() => sendPasswordResetLink()}>Şifre Sıfırlama Linki Gönder</button></div>
                                            <div className="col-lg-3"><button type="button" className="btn btn-info" onClick={() => edit()}>Güncelle</button></div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>);
}

const mapDispatchToProps = {
    appendData
}

const mapStateToProps = state => ({
    token: state.token,
    userInfos: state.userInfos
});

export default connect(mapStateToProps, mapDispatchToProps)(UserEdit);