import { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import {useNavigate } from 'react-router-dom';
import Alert from "../../Partials/Modals/Alert/alert";
import { apiUrl } from "../../../config";
import OperatorType from "../../Partials/operatorType";
import AccessControl from "../../Partials/accessControl";
import Form from "../../Partials/form";

const OperatorEdit = () => {
    const [userInfo,setUserInfo] = useState();
    const [adminAccessControls,setAdminAccessControls] = useState();
    const [alert,setAlert] = useState(false);
    const username = useRef();
    const name = useRef();
    const surname = useRef();
    const email = useRef();
    const operatorType = useRef();
    const accessType = useRef();
    const token = useSelector((state) => state.token);
    const navigate = useNavigate();
    const paths = window.location.pathname.split("/");

    useEffect(() => {
        if(paths && paths.length > 2){
            async function fetchData() {
                const data = await fetch(apiUrl + '/api/users/' + paths[2], {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        authorization: `Bearer ${token}`,
                    }
                });
                setUserInfo(await data.json());
            }
            fetchData();
        }
    },[])

    const cancel = () => {
        navigate('/operatorler');
    }
    
    const edit = async() => {
        const data = await fetch(apiUrl + '/api/users/' + paths[2], {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                username: username.current ? username.current.value : "",
                name: name.current ? name.current.value : "",
                surname: surname.current ? surname.current.value : "",
                email: email.current ? email.current.value : "",
                user_role: { 'connect': [operatorType.current ? operatorType.current.value : ""] },
                admin_access_control: {'connect' : [accessType.current ? accessType.current.value : ""] }
            })
        });
        const response = await data.json();
        if(response && response.id){
            setAlert(true);
        }
    }

    return (<>
        {alert ? <Alert title={"Operator Bilgileri Güncelleme"} description={"Operator bilgileri güncellendi."} state={alert} setState={setAlert}/> : null}
        <div className="row">
            <div className="col-lg-12">
                <div className="content-wrapper">
                    <div className="row page">
                        <div className="col-lg-12 pageTitle">
                            <h1>Operator Bilgisi Düzenleme</h1>
                        </div>
                        <div className="col-lg-12 pageContent">
                            <div className="box box-info">
                                <form className="form-horizontal">
                                    <div className="box-body">
                                        <Form keys={["username","name","surname","email"]} refs={[username,name,surname,email]} texts={["Kullanıcı Adı","İsim","Soyisim","Email"]} userInfo={userInfo}/>
                                        <OperatorType operatorType={operatorType}/>
                                        <AccessControl accessType={accessType} adminAccessControls={adminAccessControls} setAdminAccessControls={setAdminAccessControls}/>
                                    </div>
                                    <div className="box-footer">
                                        <div className="row">
                                            <div className="col-lg-3"><button type="button" className="btn btn-default" onClick={() => cancel()}>Vazgeç</button></div>
                                            <div className="col-lg-6"></div>
                                            <div className="col-lg-3"><button type="button" className="btn btn-info" onClick={() => edit()}>Güncelle</button></div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default OperatorEdit;