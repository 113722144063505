import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { apiUrl } from "../../config";

const AccessControl = ({accessType,adminAccessControls,setAdminAccessControls}) => {
    const token = useSelector((state) => state.token);

    useEffect(() => {
        async function fetchData() {
            const response = await fetch(apiUrl +"/api/admin-access-controls?filters[name][$not]=superAdmin",
            {
                headers: {
                    authorization: `Bearer ${token}`,
                }
            });
            let result = await response.json();
            console.log({result})
            setAdminAccessControls(result.data);       
        }
        fetchData();
    },[]);

    return (
        <div className="form-group">
            <label htmlFor="inputEmail" className="col-sm-2 control-label">Admin Erişim Tipi</label>

            <div className="col-sm-10">
                <select className="form-select" aria-label="Erişim Tipi" ref={accessType}>
                    <option defaultValue={"selected"}>Admin Erişim Tipi Seçiniz</option>
                    {adminAccessControls && adminAccessControls.length > 0 ? 
                    adminAccessControls.map((item,index) => 
                        <option key={index} value={item.id}>{item.attributes.name}</option>
                    ) : null}
                </select>                                            
            </div>
        </div>
    )
}

export default AccessControl;