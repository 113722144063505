import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Table from "../../Partials/Table/table";
import Alert from "../../Partials/Modals/Alert/alert";
import ConfirmationModal from "../../Partials/Modals/Confirmation/confirmation";
import {useNavigate } from 'react-router-dom';
import { apiUrl } from "../../../config";

const Tickets = () => {
    const [pageName,setPageName] = useState("tickets");
    const [page,setPage] = useState(1);
    const [values,setValues] = useState([]);
    const [alert,setAlert] = useState(false);
    const [reason,setReason] = useState("");
    const [searchText,setSearchText] = useState("");
    const [confirmationModalForAnswer,setConfirmationModalForAnswer] = useState();
    const [confirmationModalForDelete,setConfirmationModalForDelete] = useState();
    const token = useSelector((state) => state.token);
    const userInfos = useSelector((state) => state.userInfos);
    const navigate = useNavigate();

    const titles = ["id","Kullanıcı Adı","Email","Konu","Mesaj","Tarih","İşlemler"];
    const keys = ["id","attributes.user.data.attributes.username","attributes.user.data.attributes.email","attributes.subject","attributes.message","attributes.createdAt","action"];

    useEffect(() => {
        async function fetchData() {
            let url = '/api/tickets?populate=user&pagination[start]='+ ((page - 1) * 10) +'&pagination[limit]=10';
            if(searchText !== ""){
                url = '/api/tickets?populate=user&filters[user][username][$containsi]=' + searchText+ '&pagination[start]='+ ((page - 1) * 10) +'&pagination[limit]=10';
            }
            const response = await fetch(apiUrl + url,
            {
                headers: {
                    authorization: `Bearer ${token}`,
                }
            });
            setValues(await response.json());       
        }
        fetchData();
    },[page,confirmationModalForDelete,searchText]);

    const answerTicket = async(row) => {
        setConfirmationModalForAnswer(row);
    }

    const deleteTicket = (row) => {
        setConfirmationModalForDelete(row);
    }

    let functions = [];
    if(userInfos && userInfos.admin_access_control && userInfos.admin_access_control.controls && userInfos.admin_access_control.controls["tickets-answer"] && userInfos.admin_access_control.controls["tickets-answer"].checked){
        functions.push({function: answerTicket,tooltip: "Cevapla", icon: "fa fa-check"});
    }
    if(userInfos && userInfos.admin_access_control && userInfos.admin_access_control.controls && userInfos.admin_access_control.controls["tickets-delete"] && userInfos.admin_access_control.controls["tickets-delete"].checked){
        functions.push({function: deleteTicket, tooltip: "Sil",icon: "fa fa-trash"});
    }
    useEffect(() => {
        if(reason == "delete"){
            async function fetchData() {
                const data = await fetch(apiUrl+'/api/tickets/' + confirmationModalForDelete.id, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        authorization: `Bearer ${token}`,
                    }
                });
                let result = await data.json();
                if(result && result.data && result.data.id){
                    setConfirmationModalForDelete();
                    setAlert(true);
                }
            }
            fetchData();
        }else if(reason == "answer"){
            navigate('/ticket-cevaplama/' + confirmationModalForAnswer.id);
        }
    },[reason]);

    return(<>
       {alert ? <Alert title={"Ticket Durumu"} description={reason == "delete" ? "Ticket Silindi" : "Ticket Cevaplandı."} state={alert} setState={setAlert}/> : null}
       {confirmationModalForAnswer ? <ConfirmationModal title={"Ticket Cevaplama"} description={"Ticket'ı cevaplamayı onaylıyor musunuz?"} state={confirmationModalForAnswer} setState={setConfirmationModalForAnswer} setReason={setReason} reason={"answer"}/> : null}
       {confirmationModalForDelete ? <ConfirmationModal title={"Ticket Silme"} description={"Ticket'ı silmek istediğinize emin misinz ?"} state={confirmationModalForDelete} setState={setConfirmationModalForDelete} setReason={setReason} reason={"delete"}/> : null}
        <div className="row">
            <div className="col-lg-12">
                <div className="content-wrapper">
                    <div className="row page">
                        <div className="col-lg-12 pageTitle">
                            <h1>Ticketlar</h1>
                        </div>
                        <div className="col-lg-12 pageContent">
                            <Table pageName={pageName} titles={titles}  values={values.data} valueTotalCount={values.totalCount} keys={keys} page={page} setPage={setPage} functions={functions} setSearchText={setSearchText}/>
                        </div>
                    </div>
                </div>
            </div>
        </div></>
    )
}

export default Tickets;