
const loadState = () => {
  try {
    const serializedState = localStorage.getItem('state');
    if(serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (e) {
    return undefined;
  }
};

const persistedState = loadState();

const INITIAL_STATE = {
	token: persistedState && persistedState.token !== "" ? persistedState.token : "",
	userInfos: persistedState && persistedState.userInfos !== "" ? persistedState.userInfos : "",
}

export default (state = INITIAL_STATE, action={}) => {
	switch(action.type) {
		case "SET_DATA":
			return {
				...state,
				...action.content
			};
		default:
			return state;
	}
};

