
export const saveState = (state) => {
    try {
      const serializedState = JSON.stringify(state);
      localStorage.setItem('state', serializedState);
    } catch (e) {
    }
};

const setData = (content) => {
	return {
		type: "SET_DATA",
		content
	}
}

const appendData = (obj) => {
	return (dispatch) => {
		dispatch(setData(obj));
        saveState(obj);
	}
}

export {
	appendData
}