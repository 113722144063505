import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Table from "../../Partials/Table/table";
import Alert from "../../Partials/Modals/Alert/alert";
import ConfirmationModal from "../../Partials/Modals/Confirmation/confirmation";
import {useNavigate } from 'react-router-dom';
import { apiUrl } from "../../../config";

const Users = () => {
    const [pageName,setPageName] = useState("users");
    const [page,setPage] = useState(1);
    const [values,setValues] = useState([]);
    const [alert,setAlert] = useState(false);
    const [reason,setReason] = useState("");
    const [searchText,setSearchText] = useState("");
    const [sort,setSort] = useState(1);
    const [confirmationModalForStatus,setConfirmationModalForStatus] = useState();
    const [confirmationModalForEdit,setConfirmationModalForEdit] = useState();
    const [confirmationModalForDelete,setConfirmationModalForDelete] = useState();
    const token = useSelector((state) => state.token);
    const userInfos = useSelector((state) => state.userInfos);
    const navigate = useNavigate();

    const now = new Date();
    const today = new Date(now);
    today.setHours(0, 0, 0, 0);
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); 
    const day = String(today.getDate()).padStart(2, '0'); 
    const todayToIsoString = `${year}-${month}-${day}T00:00:00.000Z`;
    
    const [startDate, setStartDate] = useState(todayToIsoString);
    const [finishDate, setFinishDate] = useState(todayToIsoString);
    const [searchD, searchDate] = useState(false);
    const [dateParam, setDateParam] = useState("Kayıt");
    const [searchParam, setSearchParam] = useState("Kullanıcı Email");


    const titles = ["id","Kullanıcı Adı","Email","Engel Durumu","Onay Durumu","İşlemler"];
    const keys = ["id","username","email","blocked","confirmed","action"];

    useEffect(() => {
        async function fetchData() {
            console.log(sort)
            let srt = sort == 1 ? 'sort=id:desc' : 'sort=id:asc';
            if (searchD) {
                srt += `&filters[$and][0][createdAt][$lt]=${finishDate}&filters[$and][1][createdAt][$gt]=${startDate}`
            }
            let url = '/api/users?'+srt+'&pagination[start]='+ ((page - 1) * 10) +'&pagination[limit]=10';
            if(searchText !== ""){
                url = '/api/users?'+srt+'&filters[username][$containsi]=' + searchText+ '&pagination[start]='+ ((page - 1) * 10) +'&pagination[limit]=10';
            }
            const response = await fetch(apiUrl + url,
            {
                headers: {
                    authorization: `Bearer ${token}`,
                }
            });
            const data = await response.json()
            console.log({data})
            setValues({data});       
        }
        fetchData();
    },[page,sort,searchText,confirmationModalForStatus,confirmationModalForDelete,startDate,finishDate]);

    const changeStatusForUser = async(row) => {
        setConfirmationModalForStatus(row);
    }

    const editUser = (row) => {
        setConfirmationModalForEdit(row);
    }

    const deleteUser = (row) => {
        setConfirmationModalForDelete(row);
    }

    let functions = [];
    console.log({userInfos})
    if(userInfos && userInfos.admin_access_control && userInfos.admin_access_control.controls && userInfos.admin_access_control.controls["users-changeStatus"] && userInfos.admin_access_control.controls["users-changeStatus"].checked){
        functions.push({function: changeStatusForUser, tooltip: "Durumunu Değiştir", icon: "fa fa-check"});
    }
    if(userInfos && userInfos.admin_access_control && userInfos.admin_access_control.controls && userInfos.admin_access_control.controls["users-edit"] && userInfos.admin_access_control.controls["users-edit"].checked){
        functions.push({function: editUser, tooltip: "Düzenle", icon: "fa fa-pencil-square-o"});
    }
    if(userInfos && userInfos.admin_access_control && userInfos.admin_access_control.controls &&  userInfos.admin_access_control.controls["users-delete"] && userInfos.admin_access_control.controls["users-delete"].checked){
        functions.push({function: deleteUser, tooltip: "Sil", icon: "fa fa-trash"});
    }

    useEffect(() => {
        if(reason == "changeStatus"){
            async function fetchData() {
                const data = await fetch(apiUrl+'/api/users/' + confirmationModalForStatus.id + "?pagination[page]=" + page + "&pagination[pageSize]=10", {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        confirmed: confirmationModalForStatus.confirmed ? false : true
                    })
                });
                let result = await data.json();
                if(result && result.id){
                    setConfirmationModalForStatus();
                    setReason("");
                    setAlert(true);
                }
            }
            fetchData();
        }else if(reason == "edit"){
            navigate('/kullanici-duzenleme/' + confirmationModalForEdit.id);
        }else if(reason == "delete"){
            async function fetchData() {
                const data = await fetch(apiUrl+'/api/users/' + confirmationModalForDelete.id, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        authorization: `Bearer ${token}`,
                    }
                });
                let result = await data.json();
                if(result && result.id){
                    setConfirmationModalForDelete();
                    setReason("");
                    setAlert(true);
                }
            }
            fetchData();
        }
    },[reason]);
    return(<>
       {alert ? <Alert title={reason == "delete" ? "Kullanıcı Bilgisi Silme" : "Kullanıcı Durumunu Değiştirme"} description={reason == "delete" ?  "Kullanıcı verisi silindi." : "Kullanıcı durum bilgisi değiştirildi."} state={alert} setState={setAlert}/> : null}
       {confirmationModalForStatus ? <ConfirmationModal title={"Durum Değişikliği"} description={"Durum değişikliği yapmayı onaylıyor musunuz?"} state={confirmationModalForStatus} setState={setConfirmationModalForStatus} setReason={setReason} reason={"changeStatus"}/> : null}
       {confirmationModalForEdit ? <ConfirmationModal title={"Kullanıcı Bilgisi Düzenleme"} description={"Düzenleme yapmak istediğinize emin misinz ?"} state={confirmationModalForEdit} setState={setConfirmationModalForEdit} setReason={setReason} reason={"edit"}/> : null}
       {confirmationModalForDelete ? <ConfirmationModal title={"Kullanıcı Bilgisi Silme"} description={"Silmek istediğinize emin misinz ?"} state={confirmationModalForDelete} setState={setConfirmationModalForDelete} setReason={setReason} reason={"delete"}/> : null}
        <div className="row">
            <div className="col-lg-12">
                <div className="content-wrapper">
                    <div className="row page">
                        <div className="col-lg-12 pageTitle">
                            <h1>Kullanıcılar</h1>
                        </div>
                        <div className="col-lg-12 pageContent">
                            <Table pageName={pageName} titles={titles}  values={values.data} valueTotalCount={values.totalCount} keys={keys} page={page} setPage={setPage} functions={functions} setSearchText={setSearchText}  setSort={setSort} setStartDate={setStartDate} setFinishDate={setFinishDate} searchDate={searchDate} dateParam={dateParam} searchParam={searchParam}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default Users;